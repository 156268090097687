import React, {useState} from "react";
import { useLocation } from "react-router-dom";
import NavBarComponent from "../../../CommonComponents/NavBarComponent";
import ok from "../../../assets/ok.gif"
export default function MemberSuccessComponentWrapper(props) {

    const [msg, setMsg] = useState(""); 

    return (
        <div className="">
            <NavBarComponent />
            <div className="main-card">
            {/* <h1>{props.msg}</h1> */}
            <img className="w-16" src={ok} alt="loading..." />
            <h1 className="medium-text">{props.msg}</h1>
            </div>
        </div>
    )


}

// export default function ReceivePendingPaymentComponentWrapper(props) {
    
//     return <ReceivePendingPaymentComponent data={location.state} />
// }
// export default function Loader() {
//     return (
//         <div>
//             Vasant
//         </div>
//     )
// }
import React from "react"
import { Icon1 } from "./Icon1"
import { Icon2, Icon3, Icon4 } from "./Icon1"
// import i1bg from "../../assets/LoaderIcons/i1bg.svg"
// import i2bg from "../../assets/LoaderIcons/i2bg.svg"

export default class Loader extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentIndex : 0,
            loaderMsg: "Loading"
        }
        this.imgArr = [<Icon1 />, <Icon2 />, <Icon3 />,<Icon4 />]
    }

    componentDidMount() {
        this.updateImages()
        if(this.props.msg && this.props.msg !== '') {
            this.setState({
                loaderMsg: this.props.msg
            })
        }
        
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    updateImages = () => {
        this.interval = setInterval(() => {
            // 
            this.setState((prevState) => {
                if(prevState.currentIndex === this.imgArr.length-1) {
                    return {
                        currentIndex : 0
                    }
                } else {
                    return {currentIndex: prevState.currentIndex + 1}
                }
            })
        }, 500)
    }

   



    render() {
         
        return (
            <div>
                    
                    <div role="status" className='fixed top-0 left-0 bottom-0 right-0 inset-0 bg-opacity-10 backdrop-blur-sm flex flex-col justify-center items-center z-100'>     
                    {this.imgArr[this.state.currentIndex]} 
                    
                    <h1 className="my-2 text-xl animate-text bg-gradient-to-r from-gray-300  to-gray-800 bg-clip-text text-transparent md:text-2xl font-black">{this.state.loaderMsg}</h1>
                    <span className="sr-only">Loading...</span>
                    
                </div>
              
                
            </div>
        )
    }
}
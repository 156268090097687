import React from 'react';
import { BaseLogicHOC } from "../../../HOC/BaseLogicHOC";
import { useLocation, useMatch } from 'react-router-dom';
import { NavigationBarHOC } from "../../../HOC/NavigationBarHOC";
import { MainServices } from '../../../../services/MainServices';
import { getStringFromDate } from '../../../../helpers/Functions/DateToString';
import jsPDF from 'jspdf';

class TransactionReceiptComponentInner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoadin: true
        }
        this.myRef = React.createRef();
    }
    componentDidMount() {
        this.fetchTrans()
    }

    handleGeneratePdf = async () => {
		const doc = new jsPDF({
			format: 'a4',
			unit: 'px',
		});
        let x = 40
       


        // doc.setFont("helvetica");
        // doc.setFont("bold");
        // doc.setFontSize(15);
        doc.setFont(undefined, 'bold').setFontSize(20).text("Beyond Fitness", x,50)
        doc.setFontSize(15);
        doc.setFont(undefined, 'normal')
        let pay = "Payment Receipt - " + this.state.data.paymentId
        doc.text(pay, x, 70)
        doc.line(x,75, 400, 75)

        let name = "Name: " + this.state.data.gymMembership.gymMember.name
        doc.text(name, x, 100)

        let mobile = "Mobile number: " + this.state.data.gymMembership.gymMember.mobileNumber
        doc.text(mobile, x, 120)
        
        // doc.line(x,140, 400, 140)
        let date = "Payment Date: " + getStringFromDate(this.state.data.dateOfPayment)
        doc.text(date, x, 140)


        let mode = "Payment Mode: " + this.state.data.modeOfPayment
        doc.text(mode, x, 160)

        let rec = "Received By: " + this.state.data.receivedBy
        doc.text(rec, x, 180)

        let remarks = "Remarks: " + this.state.data.remarks
        doc.text(remarks, x, 200)

        doc.setFont(undefined, 'bold').setFontSize(20).text("Beyond Fitness", x,50)

        let amnt = "Amount Paid: "  + this.state.data.amount
        doc.text(amnt, x, 225)


        let pdfName = this.state.data.paymentId + ".pdf"
        doc.save(pdfName)

        


		// doc.html(this.myRef.current, {
		// 	callback(doc) {
		// 		doc.save('document.pdf');
		// 	},
		// });
	};

    fetchTrans = () => {
        let postJson = {
            "transactionId": this.props.transID
        }
        this.props.showLoader()
        MainServices.postRequest("/admin/transaction", postJson).then(
            res => {
                
                this.setState({'data': res})
                console.log('🚀🚀🚀=== res TransactionReceiptComponent.js [20] ===', res);
                this.props.hideLoader();
                this.setState({
                    isLoadin: false
                })
                this.handleGeneratePdf()

            },
            error => {
                
                this.props.hideLoader()
            })
    }

    render() {
        if(this.state.isLoadin) {
            return <h1>Loading</h1>
        }
        return (
            <div className="main-card space-y-2 p-6" >
                <button className="button-bf" onClick={this.handleGeneratePdf}>
				Download Receipt
			</button>
                <div className='p-4' ref={this.myRef}>
                <h1 className='header-label'>Beyond Fitness Payment Receipt</h1>
                <h1>{this.state.data.paymentId}</h1>
                <h1 className='medium-text'>{this.state.data.gymMembership.gymMember.name}</h1>
                <h1>Mobile: {this.state.data.gymMembership.gymMember.mobileNumber}</h1>
                <hr />
                <div className='my-4' >
                    <h1>Amount Paid: {this.state.data.amount}</h1>
                    <h1>Payment Date: {getStringFromDate(this.state.data.dateOfPayment)}</h1>
                    <h1>Payment Mode: {this.state.data.modeOfPayment}</h1>
                    <h1>Received By: {this.state.data.receivedBy}</h1>
                    <h1>Remarks: {this.state.data.remarks}</h1>
                </div>
                </div>
                
            </div>

        )
    }

}



const TransactionReceiptComponent = BaseLogicHOC(NavigationBarHOC(TransactionReceiptComponentInner))

export default function TransactionReceiptComponentWrapper(props) {
    const location = useLocation()
    const match = useMatch('/admin/dashboard/transaction/:item');
    return <TransactionReceiptComponent transID={match.params.item} />
}
import React, { useState, useRef } from 'react';
import { AuthService } from '../../../services/admin/AuthService';
import ManageAuth from "../../../services/admin/ManageAuth";
import { Navigate } from "react-router-dom";
import AppConfig from '../../../AppConfig';
import NavBarComponent from '../../../CommonComponents/NavBarComponent';
import Loader from '../../../helpers/Loader/loader';
import { MainServices } from '../../../services/MainServices';
import CreatableSelect, { useCreatable } from 'react-select/creatable';
import Webcam from "react-webcam";
import styles from '../sign.css'
// import {Camera} from "react-camera-pro";
import SignaturePad from 'react-signature-canvas'
import CameraComponent from '../Camera/CameraComponent';
import 'react-html5-camera-photo/build/css/index.css';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import InfoModalComponent from '../../../CommonComponents/InfoModal/InfoModalComponent';
import TextFieldComponent from '../../../CommonComponents/InputFieds/TextField';
import MemberSuccessComponent from './MemberSuccessComponent';
import CameraFullPageComponent from '../../../CommonComponents/Camera/CameraFullPageComponent';
import { BaseLogicHOC } from '../../HOC/BaseLogicHOC';


class OneTimeComponentInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'isLoading': true,
            'loaderMsg': "",
            "typeOfTraining": '',
            transactions: [{
                iid: 1
            }]
        };
        this.durationArr = []
        this.timeSlotData = []
        this.height = []

        this.setupTimeSlots()
        this.setupHeight()
    }



    setupTimeSlots = () => {
        for (let i = 6; i < 12; i++) {
            let val = i + " am - " + (i + 1) + " am"
            let data = { value: val, label: val }
            this.timeSlotData.push(data)
            if (i == 11) {

                let data2 = { value: "11:30 am - 12:30 pm", label: "11:30 am - 12:30 pm" }
                this.timeSlotData.push(data2)
            } else {
                let val2 = i + ":30 am - " + (i + 1) + ":30 am"
                let data2 = { value: val2, label: val2 }
                this.timeSlotData.push(data2)
            }
        }
        this.timeSlotData.push({ value: "12 pm - 1 pm", label: "12 pm - 1 pm" },)
        this.timeSlotData.push({ value: "12:30 pm - 1:30 pm", label: "12:30 pm - 1:30 pm" },)

        for (let i = 1; i < 10; i++) {
            let val = i + " pm - " + (i + 1) + " pm"
            let data = { value: val, label: val }
            this.timeSlotData.push(data)
            if (i == 9) {

            } else {
                let val2 = i + ":30 pm - " + (i + 1) + ":30 pm"
                let data2 = { value: val2, label: val2 }
                this.timeSlotData.push(data2)
            }
        }
    }

    setupHeight = () => {
        for (let i = 100; i < 216; i++) {
            let data = i
            this.height.push(data)
        }
    }

    setLoading = (value) => {
        this.setState({ isLoading: value })
    }



    componentDidMount() {
        MainServices.getRequest("/admin/get-pricing").then(
            res => {

                this.data = res
                this.setLoading(false)

            },
            error => {

                this.setLoading(false)
            })
    }

    calculateAge = dob => {
        const birthDate = new Date(dob);
        const difference = Date.now() - birthDate.getTime();
        const age = new Date(difference);
        return Math.abs(age.getUTCFullYear() - 1970);
    }

    handleChange = (event) => {
        const { id, value } = event.target;
        if (id == "dateOfBirth") {
            let dateObj = new Date(value)
            this.setState({
                age: this.calculateAge(dateObj)
            })
        }
        this.setState({
            [id]: value
        })
    }

    handleTrans = (iid, key, value) => {
        const newTrans = this.state.transactions.map( (obj) => {
            if(obj.iid === iid) {
                let newObj = {
                    ...obj
                }
                console.log('🚀🚀🚀=== key, value, iid OneTimeComp.js [129] ===', key, value, iid);
                newObj[key] = value
                return newObj

            }
            return obj
        })
        this.setState({transactions: newTrans})
    }

    handleSelectTraining = (event) => {
        let val = event.target.value
        let selectedTraining = this.data.filter((obj) => (obj._id == val))
        this.pricingArrForSelectedTraining = selectedTraining[0].pricing

        this.setState({
            "typeOfTraining": val,
        })
    }

    finalSubmit = (pic, sign) => {
        console.log('🚀🚀🚀=== this.state OneTimeComp.js [147] ===', this.state);
        // this.setState({
        //     "photo": pic,
        //     "signature": sign
        // }, () => { this.postServiceCall() })

        // this.setState({ loaderMsg: "Please wait while we submit the registration form..." })
        // this.setLoading(true)
        // // this.setLoaderMsg("Please wait while we submit your application")


    }

    postServiceCall = () => {
        this.setLoading(true)
        console.log('🚀🚀🚀=== JSON OneTimeComp.js [166] ===', JSON.stringify(this.state));
        MainServices.postRequest1("/admin/onetime", this.state).then(
            res => {
                this.setLoading(false)
                let endDate = new Date(res.endDate).toDateString()
                let msg = "Registration Successfull, please contact Beyond Fitness. Your Membership expires on" + endDate
                this.props.showModal(this.props.success, msg)
                // this.setState({ successMsg: msg, successScreen: true })

                // this.showModal(msg, 'success')
            },
            error => {
                
                this.setLoading(false)
                this.props.showModal(this.props.failure, error)

            })

    }

    addAnotherTrans = () => {
        let length = this.state.transactions.length
        let newTrasFields = { iid: length + 1 }
        this.setState({
            transactions: [...this.state.transactions, newTrasFields]
        })
    }


    render() {
        if (this.state.isLoading) {
            return (<Loader msg={this.state.loaderMsg} />)
        }
        let sucess = null
        if (this.state.successScreen) {
            return <MemberSuccessComponent msg={this.state.successMsg} />
        }

        return (<div>

            <section className="">
                <div className="main-card">
                    <div className="">

                        <h6 className="text-blueGray-500 p-4 text-sm font-bold">
                            One Time
                        </h6>


                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                            <form onSubmit={this.postServiceCall}>
                                <button onClick={this.finalSubmit}>Log</button>
                                <TextFieldComponent changeFn={this.handleChange} id="name" text="Name" type="text" isRequired={true} />

                                <TextFieldComponent changeFn={this.handleChange} id="dateOfBirth" text="Date of birth" type="date" isRequired={true} />

                                {/* Gender */}
                                <div className="mb-6 p-2">
                                    <label htmlFor="height" className="input-label">Gender *</label>

                                    <select
                                        onChange={this.handleChange}
                                        id="gender" className="input-bf" required>
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>

                                {this.state.age ? <label htmlFor="height" className="input-label">Age: {this.state.age}</label> : null}
                                <div className='grid  grid-cols-2 divide-x'>
                                    {/* Height */}
                                    <div className="p-2">
                                        <label htmlFor="height" className="input-label">Height *</label>
                                        <div className='flex'>
                                            <select onChange={this.handleChange} id="height" className="input-bf" required>
                                                <option value="">Select</option>
                                                {this.height.map((height) => {
                                                    // 
                                                    return <option key={height} value={height}>{height} cm</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    {/* Wight */}
                                    <TextFieldComponent changeFn={this.handleChange} id="weight" text="Weight" type="number" isRequired={true} />
                                </div>

                                {/* mobile */}
                                <TextFieldComponent changeFn={this.handleChange} id="mobileNumber" text="Mobile Number" type="number" isRequired={true} />

                                {/* emergency */}
                                <TextFieldComponent changeFn={this.handleChange} id="emergencyContact" text="Emergency Number" type="number" isRequired={true} />

                                {/* email */}
                                <TextFieldComponent changeFn={this.handleChange} id="email" text="Email" type="text" isRequired={true} />

                                {/* residentialAddress */}
                                <TextFieldComponent changeFn={this.handleChange} id="residentialAddress" text="Address" type="text" isRequired={false} />

                                {/* Type of training */}
                                <div className="mb-6 p-2">
                                    <label htmlFor="height" className="input-label">Type of training</label>

                                    <select
                                        onChange={this.handleSelectTraining}
                                        id="typeOfTraining" className="input-bf">
                                        <option value="">Select type of Training</option>
                                        {this.data.map((trainingType) => {

                                            return <option key={trainingType.id} value={trainingType._id}>{trainingType.typeOfTraining}</option>
                                        })}
                                    </select>
                                </div>

                                {/*  duration */}
                                {this.state.typeOfTraining == '' ? <></> :
                                    <div className="mb-6 p-2">
                                        <label htmlFor="height" className="input-label">Duration</label>

                                        <select
                                            onChange={this.handleChange}
                                            id="duration" className="input-bf">
                                            <option value="">Select Duration</option>
                                            {this.pricingArrForSelectedTraining.map((pricing) => {

                                                return <option key={pricing.id} value={pricing._id}>{pricing.duration} Cost: {pricing.price}</option>
                                            })}
                                        </select>
                                    </div>
                                }


                                {/* dateOfJoining */}
                                <TextFieldComponent changeFn={this.handleChange} id="dateOfJoining" text="Date of joining" type="date" isRequired={true} />

                                {/* Time Slot */}
                                <div className="mb-6 p-2">
                                    <label htmlFor="mobile" className="input-label">Time Slot</label>
                                    {/* <CreatableSelect isClearable options={this.timeSlotData} /> */}

                                    <select onChange={this.handleChange} id="timeSlot" className="input-bf">
                                        <option value="">Select timeslot</option>
                                        {this.timeSlotData.map((height) => {
                                            // 
                                            return <option key={height.label} value={height.label}>{height.label}</option>
                                        })}
                                    </select>
                                </div>

                                {/* Injuries */}
                                <TextFieldComponent changeFn={this.handleChange} id="injuries" text="Injuries if any" type="text" isRequired={false} />

                                {/* purposeOfTraining */}
                                {/* purposeOfTraining */}
                                <div className="p-2">
                                    <label htmlFor="height" className="input-label">Purpose Of Training *</label>
                                    <div className='flex'>
                                        <select onChange={this.handleChange} id="purposeOfTraining" className="input-bf" required>
                                            <option value="">Select</option>
                                            {AppConfig.purposeList.map((purpose) => {
                                                // 
                                                return <option key={purpose} value={purpose}>{purpose}</option>
                                            })}
                                        </select>


                                    </div>
                                </div>
                                {/* <TextFieldComponent changeFn={this.handleChange} id="purposeOfTraining" text="Purpose of training" type="text" isRequired={false} /> */}


                                <hr />
                                <h1 className='header-label'>Payment Details</h1>

                                {this.state.transactions.map((val) => {
                                    return <TransactOneComp key={val.iid} uid={val.iid} handleChange={this.handleTrans}/>
                                })}

                                <button className='button-bf' onClick={this.addAnotherTrans}>Add Another</button>
                                <div className="text-center mt-6">
                                    <button type="submit" className="button-bf">Submit</button>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>

            </section>
        </div>)
    }
}

class TransactOneComp extends React.Component {
    handleChange = (event) => {
        const { id, value } = event.target;
        this.props.handleChange(this.props.uid, id, value)
        // this.setState({
        //     [id]: value
        // })
    }

    render() {
        return (
            <div className='my-4'>
                <h1 className='medium-text'>Payment {this.props.uid}</h1>
                {/* Amount paid by user */}
                <TextFieldComponent changeFn={this.handleChange} id="amountPaid" text="Amount Paid by user" type="number" isRequired={true} />


                {/* remarks */}
                <TextFieldComponent changeFn={this.handleChange} id="remarks" text="Remarks" type="text" isRequired={false} />

                {/*mode of payment */}
                <div className="p-2">
                    <label for="modeOfPayment" className="block text-sm font-medium text-gray-900 dark:text-white pb-2">Payment Mode *</label>
                    <div className='flex'>
                        <select onChange={this.handleChange} id="modeOfPayment" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                            <option value="">Select</option>
                            <option value="CASH">Cash</option>
                            <option value="UPI">UPI</option>
                        </select>


                    </div>
                </div>

                <div className="p-2">
                    <label for="modeOfPayment" className="block text-sm font-medium text-gray-900 dark:text-white pb-2">Payment Type *</label>
                    <div className='flex'>
                    <select onChange={this.handleChange} id="paymentType" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                            <option value="">Select</option>
                            <option value="New">New</option>
                            <option value="Pending">Pending</option>
                            <option value="Renewal">Renewal</option>
                        </select>


                    </div>
                </div>

                {/* discount */}
                <TextFieldComponent changeFn={this.handleChange} id="discount" text="Discount" type="number" isRequired={false} />

                <TextFieldComponent changeFn={this.handleChange} id="dateOfPayment" text="Date of payment" type="date" isRequired={true} />

                

                {/* receieved by */}
                <div className="p-2">
                    <label for="height" className="block text-sm font-medium text-gray-900 dark:text-white pb-2">Receieved By *</label>
                    <div className='flex'>
                        <select onChange={this.handleChange} id="receivedBy" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                            <option value="">Select</option>
                            {AppConfig.receivedBy.map((person) => {
                                // 
                                return <option key={person} value={person}>{person}</option>
                            })}
                        </select>
                    </div>
                </div>
                <hr />
            </div>
        )
    }
}

const OneTimeComponent = BaseLogicHOC(OneTimeComponentInner)
export default OneTimeComponent;








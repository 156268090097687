import React from "react"
import { getStringFromDate } from "../../helpers/Functions/DateToString"


export default class UserCardComponent extends React.Component {
    constructor(props) {
        super(props)
        // 
        // 
    }

    render() {
        return (
            <div className='m-2 p-4 rounded shadow-xl bg-gray-700 text-white shadow-gray-500 shadow-md rounded-lg '>
                <div className='flex space-x-2'>
                    {this.props.member.media.photo ? <img className='w-24 h-24 rounded-full object-cover flex-none' src={this.props.member.media.photo} /> : null}
                    
                    <div className='flex-1'>
                        <h1 className='font-medium'>{this.props.member.name}</h1>
                        <p>{this.props.member.mobileNumber}</p>
                        <p>{getStringFromDate(this.props.member.dateOfBirth)}</p>
                    </div>

                </div>
                
            </div>
        )
    }
}
import React from "react";
import { useLocation } from 'react-router-dom';
import { MainServices } from '../../../../services/MainServices';
import Loader from '../../../../helpers/Loader/loader';
import NavBarComponent from '../../../../CommonComponents/NavBarComponent';
import TextFieldComponent from "../../../../CommonComponents/InputFieds/TextField";
import { getStringFromDate } from "../../../../helpers/Functions/DateToString";
import AppConfig from "../../../../AppConfig";
import { NavigationBarHOC } from "../../../HOC/NavigationBarHOC";
import { BaseLogicHOC } from "../../../HOC/BaseLogicHOC";
import { TableColumn, TransactCardForMobile } from "../../../../CommonComponents/Transactions/TransactComponent";





class ReceivePendingPaymentComponentInner extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            "res": null,
            "errorStateCheck": false,
        }
        this.props.setSelectedComp(2)
    }

    componentDidMount() {
        this.fetchData()
        // this.props.showModal(this.props.success, "Payment Received a", "/admin/dashboard/transaction/641c69b65e65d0e718bf2baf")
        
    }

   
    handleclick = (id) => {
        
    }


    handleChange = (event) => {
        const { id, value } = event.target;
        this.setState({
            [id]: value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        this.receivePaymentServiceCall()
    }


    showModal = (modalType, modalMessage, redirectURL) => {
        this.setState({modalType: modalType, modalMessage: modalMessage, shouldShowModal: true, redirectURL: redirectURL})
    }
    hideModal = () => {
        this.setState({ shouldShowModal: false, redirectURL: null })
    }

     //API
     fetchData = () => {
        this.props.showLoader()
        let url = "/admin/fetch-pending-details"
        let postJson = {
            "gymMemberId": this.props.data.member._id
        }
        MainServices.postRequest(url, postJson).then(
            res => {
                
                this.setState({ res, errorStateCheck: true })
                this.props.hideLoader()

            },
            error => {
                this.props.showModal(this.props.failure, error)
                this.props.hideLoader()
            })
    }


    receivePaymentServiceCall = () => {
        this.props.showLoader()
        
        let postJson = {
            "membershipId": this.state.res.membershipId,
            "amountPaid": this.state.amountPaid,
            "modeOfPayment": this.state.modeOfPayment,
            "receivedBy": this.state.receivedBy,
            "remarks": this.state.remarks,
            "dateOfPayment": this.state.dateOfPayment
        }
        
        MainServices.postRequest("/admin/update-pending-details", postJson).then(
            res => {
                // this.showModal('success', )
                let reurl = "/admin/dashboard/transaction/" + res.transId
                this.props.showModal(this.props.success, "Payment Received", reurl)
                this.props.hideLoader()
                
            },
            error => {
                this.showModal('failure',error)
                this.props.showModal(this.props.failure, error)
                this.props.hideLoader()
                // setIsLoading(false)
            })
    }


   

    render() {
        if (this.state.isLoading) {
            
            return <Loader />
        }

        if(this.state.res === null) {
            return <></>
        }



        return (
            <div>
                <section className="">
                    <div className="main-card">
                        <div className="flex flex-col min-w-0 break-words w-full mb-6 border-0 ">
                            <div className='mb-6 '>
                            <div className="m-4 space-y-1">
                                <h3 className="header-label">{this.props.data.member.name}</h3>
                                <h5 className="medium-text">{this.props.data.member.clientCode}</h5>
                                <h5>Date of Birth: {getStringFromDate(this.props.data.member.dateOfBirth)}</h5>
                                <h5>Mobile : {this.props.data.member.mobileNumber}</h5>
                                </div>
                                <hr />
                                <div className="m-4 space-y-1">
                                <h5>Date of Joining: {getStringFromDate(this.state.res.dateOfJoining)}</h5>
                                <h5> Membership End Date: {getStringFromDate(this.state.res.membershipEndDate)}</h5>
                                <hr />
                                </div>
                                



                                <h5 className="medium-text">Transaction History</h5>
                                <div className="sm:block md:hidden">
                                    only for sm mobile
                                    {
                                                this.state.res.transactions.map( (trasact) => {
                                                    return <TransactCardForMobile data={trasact} />
                                                })
                                            }
                                </div>
                                <div className="m-4 overflow-x-auto shadow-md sm:rounded-lg">
                                    <table className="hidden md:block table-auto text-sm text-left">
                                        <thead className="text-xs  uppercase bg-gray-50">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    Date
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Payment Mode
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Received By
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Remarks
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.res.transactions.map( (trasact) => {
                                                    return <TableColumn data={trasact} />
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>

                                <div className="m-4">
                                <h5 className="medium-text">Total Amount Paid: {this.state.res.totalAmountPaid}</h5>
                                <h5 className="medium-text">Pending Amount: {this.state.res.pendingAmount}</h5>
                                </div>
                                <hr />
                                <h3 className="header-label">Receive Pending Payment</h3>
                                <form onSubmit={this.handleSubmit}>
                                {/* Amount paid by user */}
                                <TextFieldComponent changeFn={this.handleChange} id="amountPaid" text="Amount Paid by user" type="number" isRequired={true} />

                                
                                {/* remarks */}
                                <TextFieldComponent changeFn={this.handleChange} id="remarks" text="Remarks" type="text" isRequired={false} />

                                {/*mode of payment */}
                                <div className="p-2">
                                    <label for="modeOfPayment" className="input-label">Payment Mode *</label>
                                    <div className='flex'>
                                        <select onChange={this.handleChange} id="modeOfPayment" className="input-bf" required>
                                            <option value="">Select</option>
                                            <option value="CASH">Cash</option>
                                            <option value="UPI">UPI</option>
                                        </select>


                                    </div>
                                </div>

                                <TextFieldComponent changeFn={this.handleChange} id="dateOfPayment" text="Date of payment" type="date" isRequired={true} />

                                {/* receieved by */}
                                {/* <div className="p-2">
                                    <label for="height" className="input-label">Receieved By *</label>
                                    <div className='flex'>
                                        <select onChange={this.handleChange} id="receivedBy" className="input-bf" required>
                                            <option value="">Select</option>
                                            {AppConfig.receivedBy.map((person) => {
                                                // 
                                                return <option key={person} value={person}>{person}</option>
                                            })}
                                        </select>
                                    </div>
                                </div> */}
                                <button type="submit" className="button-bf">Confirm Payment</button>
                            </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

}



const ReceivePendingPaymentComponent = BaseLogicHOC(NavigationBarHOC(ReceivePendingPaymentComponentInner))

export default function ReceivePendingPaymentComponentWrapper(props) {
    const location = useLocation()
    return <ReceivePendingPaymentComponent data={location.state} />
}
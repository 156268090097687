import React from 'react';
export default class TextFieldComponent extends React.Component {

    constructor(props) {
        super(props)
        if(props.isLabelVisible === undefined) {
            this.state = {
                isLabelVisible: true
            }
        } else {
            this.state = {
                isLabelVisible: props.isLabelVisible
            }
        }
    }
    render() {
        return (
            <div className='p-2'>
                {this.state.isLabelVisible ? 
                <label htmlFor="mobile" className="input-label">{this.props.text}  {this.props.isRequired ? <a>*</a> : null}</label> 
                : 
                null
                }
                
                <input type={this.props.type} id={this.props.id}
                    onWheel={ event => event.target.blur() }
                    className="input-bf"
                    placeholder={"Please enter " + this.props.text} required={this.props.isRequired}
                    value={this.props[this.props.id]}
                    onChange={this.props.changeFn}
                />
            </div>
        )
    }
}
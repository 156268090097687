import React from 'react';
import Loader from '../../../../helpers/Loader/loader';
import UserCardComponent from '../../../../CommonComponents/UserCards/UserCardComponent';

import { MainServices } from '../../../../services/MainServices'
import  TextFieldComponent  from '../../../../CommonComponents/InputFieds/TextField';
import { Link } from "react-router-dom";
import { BaseLogicHOC } from '../../../HOC/BaseLogicHOC';
import { NavigationBarHOC } from '../../../HOC/NavigationBarHOC';
import { getStringFromDate } from '../../../../helpers/Functions/DateToString';
import { PhoneIcon } from '../../../../CommonComponents/Icons/CommonIcons';
import ToggleComponent from '../../../../CommonComponents/Toggle/ToggleComponent';



class RenewalComponentInner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            "res": [],
            'pendingRenewals': [],
            "showErrorState": false
        }
        this.props.setSelectedComp(3)
        this.fetchPendingRenewals()
    }

    fetchPendingRenewals = () => {
        this.props.showLoader()
        MainServices.postRequest("/admin/pending-renewals").then(
            res => {
                
                this.setState({'pendingRenewals': res})
                this.props.hideLoader()

            },
            error => {
                
                this.props.hideLoader()
            })
    }


    handleclick = (id) => {
        
    }


    handleChange = (event) => {
        const { id, value } = event.target;
        this.setState({
            [id]: value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        this.props.showLoader()
        let postJson = {
            "mobileNumber": this.state.mobileNumber
        }
        MainServices.postRequest("/admin/fetch-by-mobile", postJson).then(
            res => {
                
                this.setState({"pendingRenewals": res})
                this.props.hideLoader()

            },
            error => {
                
                this.props.hideLoader()
            })
    }

    markContacted = (membershipId, renewalComments) => {
        this.props.showLoader()
        let jsonPost = {
            membershipId: membershipId,
            renewalComments: renewalComments
        }
        MainServices.postRequest('/admin/hasBeenContacted', jsonPost).then(
        res => {
            
            this.props.hideLoader()
            this.setState({
                pendingRenewals: []
            })
            this.fetchPendingRenewals()

        },
        error => {
            this.props.hideLoader()
            this.props.showModal('failure', "Operation Failed")
            
        })
    }


    render() {
       
        let errorState = <h1> No Pending Member found</h1>
        // let inactiveJSX = (
        //     <div onClick={this.onClick}>
        //         <UserCardComponent member={member} />
        //     </div>
        // )
        
            return (
                <div>
                
                <section className="">
                    <div className="main-card">
                        <div className="flex flex-col min-w-0 break-words w-full mb-6 border-0 ">
                            <div className='mb-6 '>
                                <form onSubmit={this.handleSubmit}>
                                <TextFieldComponent changeFn={this.handleChange} id="mobileNumber" text="Mobile Number/Name" type="text" isRequired={true} />
                                <button type="submit" className="text-white bg-gray-700 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Search</button>
                                </form>
                            </div>
                            
                            {this.state.showErrorState ? errorState : null}
                            {this.state.res.map((member) => {
                                
                                // if(member)
                                return (<Link to="renew-member" state={{member: member}}>
                                    <UserCardComponent member={member} />
                                    </Link>) 
                            })}

                            {this.state.pendingRenewals.map((member) => {
                                
                                // if(member)
                                return (<PendingRenewalView member={member}  markContacted={this.markContacted}/>) 
                            })}

                        </div>
                    </div>
                </section>
                </div>
            )
        
        


    }
}

class PendingRenewalView extends React.Component {
 handleChange = (event) => {
        const { id, value } = event.target;
        this.setState({
            [id]: value
        })
    }
    markContacted = (e) => {
        console.log('🚀🚀🚀=== this.state RenewalComponent.js [155] ===', this.state);
        this.props.markContacted(this.props.member._id, this.state.comments)
    }
    render() {
        return (
            <div className='m-2 p-4 rounded shadow-xl bg-gray-700 text-white shadow-gray-500 shadow-md rounded-lg '>
                <div className='flex space-x-2'>
                    <div className='flex-1'>
                        {this.props.member.pendingAmount > 0 ? <div> 
                            ⚠ There is a previous pending amount of {this.props.member.pendingAmount} ⚠
                        </div> : null}
                        <h1 className='font-medium'>{this.props.member.gymMember.name}</h1>
                        <p className='flex'><PhoneIcon /> {this.props.member.gymMember.mobileNumber}</p>
                        <p>DOB: {getStringFromDate(this.props.member.gymMember.dateOfBirth)}</p>
                        <p>Pending Amount: {this.props.member.pendingAmount}</p>
                        <p>Membership Expired on: {getStringFromDate(this.props.member.membershipEndDate)}</p>
                        <p>Training: {this.props.member.typeOfTraining.typeOfTraining}</p>
                        <p>Duration: {this.props.member.duration.duration}</p>

                        <hr/>
                        <p>Not intrested for renewal?</p>
                        <form onSubmit={this.markContacted}>
                        <TextFieldComponent changeFn={this.handleChange} id="comments" text="comments" type="text" isRequired={true} isLabelVisible={false}/>
                        <button type="submit" id={this.props.member._id} className='bg-white p-2 shadow text-black rounded m-2'>Yes</button>
                        <hr/>
                        </form>
                        <Link to="renew-member" state={{member: this.props.member.gymMember, pendingAmount: this.props.member.pendingAmount}}><button className='bg-white text-black p-2 m-2 rounded font-medium'>Renew now </button></Link>
                    </div>
                    
                </div>
                
            </div>
        )
    }
}


const RenewalComponentBase = BaseLogicHOC(RenewalComponentInner)
const RenewalComponent = NavigationBarHOC(RenewalComponentBase)
export default RenewalComponent;
import React from 'react';
import { Link } from 'react-router-dom';
import { MainServices } from '../../../services/MainServices';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { data } from 'autoprefixer';

//icons
import AddMemberIcon from "../../../assets/AddMemberIcon.png"
import AdminIcon from "../../../assets/AdminIcon.png"
import PendingPaymentIcon from "../../../assets/PendingPaymentIcon.png"
import RenewalIcon from "../../../assets/RenewalIcon.png"
import ReportIcon from "../../../assets/ReportIcon.png"
import UpdateMembershipIcon from "../../../assets/UpdateMembershipIcon.png"

import Loader from '../../../helpers/Loader/loader';
import ok from "../../../assets/ok.gif"
import { useState, useRef } from "react";

import AddNewMebershipComponent from '../MemberComponents/AddNewMebershipComponent';
import PendingPaymentComponent from './PendingComponents/PendingPaymentComponent'
import RenewalComponent from './RenewalComponents/RenewalComponent'
import ReportComponent from './Reports/ReportComponent'
import GetMembershipComponent from './UpdateMembershipDetails/GetMembershipComponent'
import NavBarComponent from '../../../CommonComponents/NavBarComponent';
import UpdatePricingComponent from './UpdatePricingComponent';
import ApproveMembersComponent from "./ApproveMembers/ApproveMembersComponent"
import CameraComponent from "../Camera/CameraComponent"
import { NavigationBarHOC } from '../../HOC/NavigationBarHOC';

class AdminDashboardComponentInner extends React.Component {
    render() {
        return <h1>Home</h1>
    }
}

const AdminDashboardComponent = NavigationBarHOC(AdminDashboardComponentInner)
export default AdminDashboardComponent

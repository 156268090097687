import React from 'react';
import { AuthService } from '../../../services/admin/AuthService';
import ManageAuth from "../../../services/admin/ManageAuth";
import { Navigate } from "react-router-dom";
// import ModalComponent from '../../CommonComponents/InfoModalComponent';
import InfoModalComponent from '../../../CommonComponents/InfoModal/InfoModalComponent';
import Webcam from "react-webcam";
import { useCallback, useRef, useState } from "react";
import SignaturePad from 'react-signature-canvas'
// import SignatureCanvas from 'react-signature-canvas'
import styles from '../sign.css'
import { BaseLogicHOC } from '../../HOC/BaseLogicHOC';


class AdminSignupComponentInner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'name': '',
            'mobileNumber': Number,
            'password': '',
            're-password': '',
            'redirectDashboard': false,
            'isLoading': false,

            'showModal': false,
            'modalType': 'success',
            'modalMessage': 'Test',
            'pic': '',
            trimmedDataURL: null
        };
        this.sigPad = {}


        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this)

    }



    handleChange(event) {
        const { id, value } = event.target;
        
        this.setState({
            [id]: value
        })
        // 
        // this.setState({ name: event.target.value });
    }




    handleSubmit(event) {
        AuthService.adminsignup(this.state.mobileNumber, this.state.password, this.state.name, this.state.trimmedDataURL)
            .then(
                res => {
                    this.props.showModal(this.props.success, res.message)
                    //this.navigateToDashboard()
                },
                error => {
                    this.props.showModal(this.props.failure, error)
                })

    }

    hideModal() {
        this.setState({
            "modalMessage": '',
            "showModal": false,
            'modalType': '',
        })
    }


    navigateToDashboard() {
        this.setState({
            'redirectDashboard': true,
            'isLoading': false,
        })
        
    }

    clear = () => {
        this.sigPad.clear()
    }
    trim = () => {
        this.setState({
            trimmedDataURL: this.sigPad.getTrimmedCanvas()
                .toDataURL('image/png')
        })
    }



    render() {

        let trimmedDataURL = this.state.trimmedDataURL
        return (
            <div>          
                <section className="bg-blueGray-50">
                    <div className="w-full lg:w-4/12 px-4 mx-auto pt-6">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                            <h6 className="text-blueGray-500 p-4 text-sm font-bold">
                                Admin Sign up
                            </h6>
                            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">

                                <form onSubmit={this.handleSubmit}>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Name</label>
                                        <input id="name" type="text" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Name"
                                            value={this.state.name} onChange={this.handleChange} />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Mobile Number</label>
                                        <input id="mobileNumber" type="number" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Mobile Number"
                                            value={this.state.mobileNumber} onChange={this.handleChange} />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Password</label>
                                        <input id="password" type="password" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Password"
                                            value={this.state.password} onChange={this.handleChange} />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">Re-enter Password</label>
                                        <input id="re-password" type="password" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Password"
                                            value={this.state['re-password']} onChange={this.handleChange} />
                                    </div>

                                    <div className="text-center mt-6">
                                        <button onClick={this.handleSubmit} className="bg-blue-800 text-white active:bg-blue-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type="button"> Sign In </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </section>
            </div>)
    }
}

let AdminSignupComponent = BaseLogicHOC(AdminSignupComponentInner)
export default AdminSignupComponent;
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MainServices } from '../../../../services/MainServices';
import Loader from '../../../../helpers/Loader/loader';
import NavBarComponent from '../../../../CommonComponents/NavBarComponent';
// import TextFieldComponent from '../../../../CommonComponents/InputFieds/TextField';
import { getStringFromDate, getStringFromDateYYYmmdd } from '../../../../helpers/Functions/DateToString';
import AppConfig from "../../../../AppConfig"
import InfoModalOkBtnComponent from '../../../../CommonComponents/InfoModal/InfoModalOkBtnComponent';
import InfoModalComponent from '../../../../CommonComponents/InfoModal/InfoModalComponent';
import AdminDashboardComponent from '../AdminDashboardComponent'
import CameraFullPageComponent from "../../../../CommonComponents/Camera/CameraFullPageComponent";
import SignComponent from "../../../../CommonComponents/Sign/SignComponent";
import TextFieldControlledComponent from '../../../../CommonComponents/InputFieds/TextFieldControlledComponent';
import { NavigationBarHOC } from '../../../HOC/NavigationBarHOC';





class ApproveMemberDetailComponentPrivateInner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data,
            isLoading: true,
            isFormEditable: false,
            "isSecondScreen": false,
            showCamera: false,
            isCameraError: false,
            showSignPad: false,
            isPhotoChanged: false,
            isSignChanged: false
        }
        this.height = []
        this.timeSlotData = []
        this.setupTimeSlots()
        this.setupHeight()
        this.props.setSelectedComp(1)

    }

    moveToNextScreen = (event) => {
        event.preventDefault();
        // localStorage.setItem("State", this.state)
        this.setState({ isSecondScreen: true })
        window.scrollTo(0, 0)


    }

    setupTimeSlots = () => {
        for (let i = 6; i < 12; i++) {
            let val = i + " am - " + (i + 1) + " am"
            let data = { value: val, label: val }
            this.timeSlotData.push(data)
            if (i == 11) {

                let data2 = { value: "11:30 am - 12:30 pm", label: "11:30 am - 12:30 pm" }
                this.timeSlotData.push(data2)
            } else {
                let val2 = i + ":30 am - " + (i + 1) + ":30 am"
                let data2 = { value: val2, label: val2 }
                this.timeSlotData.push(data2)
            }
        }
        this.timeSlotData.push({ value: "12 pm - 1 pm", label: "12 pm - 1 pm" },)
        this.timeSlotData.push({ value: "12:30 pm - 1:30 pm", label: "12:30 pm - 1:30 pm" },)

        for (let i = 1; i < 10; i++) {
            let val = i + " pm - " + (i + 1) + " pm"
            let data = { value: val, label: val }
            this.timeSlotData.push(data)
            if (i == 9) {

            } else {
                let val2 = i + ":30 pm - " + (i + 1) + ":30 pm"
                let data2 = { value: val2, label: val2 }
                this.timeSlotData.push(data2)
            }
        }
    }

    setLoading = (value) => {
        this.setState({ isLoading: value })
    }

    calculateAge = dob => {
        const birthDate = new Date(dob);
        const difference = Date.now() - birthDate.getTime();
        const age = new Date(difference);

        return Math.abs(age.getUTCFullYear() - 1970);
    }

    setupHeight = () => {
        for (let i = 100; i < 216; i++) {
            let data = i
            this.height.push(data)
        }
    }



    componentDidMount() {
        for (var key in this.props.data.member) {
            this.setState({
                [key]: this.props.data.member[key]
            })

        }

        let postJson = {
            "gymMemberId": this.state.data.member._id
        }

        MainServices.postRequest("/admin/get-pending-members-details", postJson).then(
            res => {

                console.log('🚀🚀🚀=== res ApproveMemberDetailComponent.js [120] ===', res);
                for (var key in res) {
                    this.setState({
                        [key]: res[key]
                    })
                }
                this.fetchPricing()
            },
            error => {
                this.setLoading(false)
            })
    }

    fetchPricing = () => {
        this.setLoading(true)
        MainServices.getRequest("/admin/get-pricing").then(
            res => {
console.log('🚀🚀🚀=== res ApproveMemberDetailComponent.js [137] ===', res);
                this.trainingdata = res
                for (var i = 0; i < res.length; i++) {
                    if (res[i]._id === this.state.typeOfTraining) {
                        this.selectedTraining = res[i]
                        this.pricingArrForSelectedTraining = res[i].pricing
                        this.setState({
                            pricingArr: res[i].pricing,
                            selectedTraining: res[i]
                        })
                        console.log('🚀🚀🚀=== res[i] ApproveMemberDetailComponent.js [147] ===', res[i]);
                    }
                }
                for (let k = 0; k < this.pricingArrForSelectedTraining.length; k++) {
                    if (this.pricingArrForSelectedTraining[k]._id === this.state.duration) {
                        this.selectedDuration = this.pricingArrForSelectedTraining[k]
                        this.setState({
                            selectedDuration: this.pricingArrForSelectedTraining[k]
                        })
                    }
                }
                this.setLoading(false)
            },
            error => {
                this.setLoading(false)
            })
    }

    handleChange = (event) => {
        const { id, value } = event.target;
        if (id == "dateOfBirth") {
            let dateObj = new Date(value)
            this.setState({
                age: this.calculateAge(dateObj)
            })
        }

        this.setState({
            [id]: value
        })
    }

    handleSelectTraining = (event) => {
        let val = event.target.value
        let selectedTraining = this.trainingdata.filter((obj) => (obj._id == val))
        console.log('🚀🚀🚀=== val, selectedTraining ApproveMemberDetailComponent.js [182] ===', val, selectedTraining);
        this.setState({
            pricingArr: selectedTraining[0].pricing,
            selectedTraining: selectedTraining[0],
            selectedDuration: undefined
        })
        this.pricingArrForSelectedTraining = selectedTraining[0].pricing
        
        this.setState({
            "typeOfTraining": val,
            "duration": ""
        })
    }

    cameraHandler = (photo, isCameraError) => {
        
        if(isCameraError) {
            this.setState({
                "showCamera": false,
                isPhotoChanged: false
            })
            return
        } else {
            
            this.setState((prevState) => {
                return {media: {
                photo: photo,
                isPhotoChanged: true,
                signature: prevState.media.signature
            },
            showCamera: false,
        }})
        }
    }

    signHandler = (sign) => {
        this.setState((prevState) => {
            return { media: { signature: sign, photo: prevState.media.photo }, showSignPad: false, isSignChanged: true }
        })
    }

    editForm = () => {
        this.setState({
            isFormEditable: true
        })
    }

    handleBtnAction = (event) => {
        event.preventDefault()
        const {id} = event.target;
        if(id === "photo") {
            this.setState({showCamera: true})
        } else if(id === "signature") {
            this.setState({showSignPad: true})
        }
    }

    save = () => {
        this.setLoading(true)
        let postJson = {
            name: this.state.name,
            dateOfBirth: this.state.dateOfBirth,
            gender: this.state.gender,
            height: this.state.height,
            weight: this.state.weight,
            age: this.state.age,
            mobileNumber: this.state.mobileNumber,
            emergencyContact: this.state.emergencyContact,
            email: this.state.email,
            residentialAddress: this.state.residentialAddress,
            injuries: this.state.injuries,
            purposeOfTraining: this.state.purposeOfTraining,
            typeOfTraining: this.state.typeOfTraining,
            dateOfJoining: this.state.dateOfJoining,
            duration: this.state.duration,
            timeSlot: this.state.timeSlot,
            memberId: this.state.data.member._id,
            membershipId: this.state._id,
            photo: this.state.media.photo
        }

        if(this.state.isSignChanged) {
            postJson["signature"] = this.state.media["signature"]
        }
        
        MainServices.postRequest("/admin/update-member", postJson).then(
            res => {
                let dict = {...this.state}
                
                for (var key in res.member) {
                    // 
                    dict[key] = res.member[key]
                }
                for (var key1 in res.membership) {
                    dict[key1] =res.membership[key1]
                }
                dict['selectedDuration'] = dict["duration"]
                this.setState({
                    media: res.media
                }
                )
                
                dict['isFormEditable'] = false
                this.setState(dict)
                // this.setState({is})
                this.setLoading(false)
                this.fetchPricing()
            },
            error => {
                alert("Error", error)
                this.setLoading(false)
            })
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div>
                    <Loader />
                </div>

            )
        }

        if(this.state.showSignPad) {
            return <SignComponent callback={this.signHandler}/>
        }

        if(this.state.showCamera) {
            return (<CameraFullPageComponent handler={this.cameraHandler} />)
        }
        if (this.state.isSecondScreen) {
            return (
                <SecondScreen data={
                    {
                        name: this.state.name,
                        membershipId: this.state._id,
                        gymMember: this.state.gymMember,
                        dateOfBirth: this.state.dateOfBirth,
                        membershipEndDate: this.state.membershipEndDate,
                        amountToBePaid: this.state.selectedDuration.price
                    }
                } />

            )
        }
        console.log('🚀🚀🚀=== this.state.selected ApproveMemberDetailComponent.js [422] ===', this.state.selectedDuration)
        return (
            <div>
                
                <section className="m-2">
                    <div className="main-card">
                    <button onClick={this.editForm}  className="button-bf m-2">Edit </button>
                        <form onSubmit={this.save}>  
                        <div className="p-2 flex flex-col min-w-0 break-words w-full mb-6 ">
                            <div className='flex space-x-4'>
                                <div>
                                    <img className='w-36 h-36 object-cover flex-none' src={this.state["media"].photo} />
                                    {this.state.isFormEditable ? <button id="photo" onClick={this.handleBtnAction}  className='button-bf m-2'>Take another pic</button> : null}
                                    
                                </div>
                                <div>
                                    <img className='w-36 h-36  flex-none' src={this.state["media"].signature} />
                                    {this.state.isFormEditable ? <button id="signature" onClick={this.handleBtnAction} className='button-bf m-2'>Take signature again</button> : null}
                                </div>
                                
                            </div>
                            
                            <TextFieldControlledComponent changeFn={this.handleChange} id="name" text="Name" type="text" isRequired={true} data={this.props.data} value={this.state["name"]} isEditable={this.state.isFormEditable} required/>

                            <TextFieldControlledComponent changeFn={this.handleChange} id="dateOfBirth" text="Date of birth" type="date" isRequired={true} value={getStringFromDateYYYmmdd(this.state["dateOfBirth"])} isEditable={this.state.isFormEditable} required />
                            
                            {/* Gender */}
                            <div className="mb-6 p-2">
                                        <label for="height" className="input-label">Gender *</label>

                                        <select
                                            onChange={this.handleChange} value={this.state["gender"]}
                                            id="gender" className="input-bf" required>
                                            <option value="">Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                    </div>

                            {this.state.age ? <label for="height" className="p-2 block text-sm font-medium text-gray-900 dark:text-white pb-2">Age: {this.state.age}</label> : null}

                            {/* Height */}
                            <div className="p-2">
                                <label for="height" className="input-label">Height *</label>
                                <div className='flex'>
                                    <select onChange={this.handleChange} id="height" className="input-bf" required disabled={!this.state.isFormEditable}>
                                        <option value={this.state["height"]}>{this.state["height"]} cm</option>
                                        {this.height.map((height) => {
                                            return <option key={height} value={height}>{height} cm</option>
                                        })}
                                    </select>
                                </div>
                            </div>

                            {/* Wight */}
                            <TextFieldControlledComponent changeFn={this.handleChange} id="weight" text="Weight" type="number" isRequired={true} value={this.state["weight"]} isEditable={this.state.isFormEditable} required/>

                            {/* mobile */}

                            <TextFieldControlledComponent changeFn={this.handleChange} id="mobileNumber" text="Mobile Number" type="number" isRequired={true} value={this.state["mobileNumber"]} isEditable={this.state.isFormEditable} />

                            {/* emergency */}
                            <TextFieldControlledComponent changeFn={this.handleChange} id="emergencyContact" text="Emergency Number" type="number" isRequired={true} value={this.state["emergencyContact"]} isEditable={this.state.isFormEditable} />


                            {/* email */}
                            <TextFieldControlledComponent changeFn={this.handleChange} id="email" text="Email" type="text" isRequired={true} value={this.state["email"]} isEditable={this.state.isFormEditable} />

                            {/* residentialAddress */}
                            <TextFieldControlledComponent changeFn={this.handleChange} id="residentialAddress" text="Address" type="text" isRequired={false} value={this.state["residentialAddress"]} isEditable={this.state.isFormEditable} />


                            {/* Type of training */}
                            
                            <div className="mb-6 p-2">
                                <label for="height" className="input-label">Type of training</label>

                                <select
                                    onChange={this.handleSelectTraining}
                                    id="typeOfTraining" className="input-bf" disabled={!this.state.isFormEditable} required>
                                    <option value={this.state.selectedTraining.typeOfTraining._id}>{this.state.selectedTraining.typeOfTraining}</option>
                                    {this.trainingdata.map((trainingType) => {
                                        return <option key={trainingType.id} value={trainingType._id}>{trainingType.typeOfTraining}</option>
                                    })}
                                </select>
                            </div>

                            {/*  duration */}
                            {this.state.typeOfTraining == '' ? <></> :
                                <div className="mb-6 p-2">
                                    <label for="height" className=" input-label">Duration</label>

                                    <select
                                        onChange={this.handleChange}
                                        id="duration" className="input-bf" disabled={!this.state.isFormEditable} required>
                                        {this.state.selectedDuration === undefined ?
                                        <option value="">Select</option>
                                        :
                                        <option value={this.state.selectedDuration._id}>{this.state.selectedDuration.duration} Cost: {this.state.selectedDuration.price}</option>
    }
                                        {this.state.pricingArr.map((pricing) => {

                                            return <option key={pricing._id} value={pricing._id}>{pricing.duration} Cost: {pricing.price}</option>
                                        })}
                                    </select>
                                </div>
                            }


                            {/* dateOfJoining */}
                            <TextFieldControlledComponent changeFn={this.handleChange} id="dateOfJoining" text="Date of joining" type="date" isRequired={true} value={getStringFromDateYYYmmdd(this.state["dateOfJoining"])} isEditable={this.state.isFormEditable} />


                            {/* Time Slot */}
                            <div className="mb-6 p-2">
                                <label for="mobile" className="input-label">Time Slot</label>
                                {/* <CreatableSelect isClearable options={this.timeSlotData} /> */}

                                <select onChange={this.handleChange} id="timeSlot" className="input-bf" disabled={!this.state.isFormEditable} required>
                                    <option value={this.state.timeSlot}>{this.state.timeSlot}</option>
                                    {this.timeSlotData.map((height) => {
                                        return <option key={height.label} value={height.label}>{height.label}</option>
                                    })}
                                </select>
                            </div>

                            {/* Injuries */}
                            <TextFieldControlledComponent changeFn={this.handleChange} id="injuries" text="Injuries if any" type="text" isRequired={false} value={this.state["injuries"]} isEditable={this.state.isFormEditable} />
                            {/* purposeOfTraining */}
                            {/* <TextFieldControlledComponent changeFn={this.handleChange} id="purposeOfTraining" text="Purpose of training" type="text" isRequired={false} value={this.state["purposeOfTraining"]} isEditable={this.state.isFormEditable} /> */}
                            {/* purposeOfTraining */}
                            <div className="p-2">
                                <label for="height" className="input-label">Purpose Of Training *</label>
                                <div className='flex'>
                                    <select value={this.state["purposeOfTraining"]} onChange={this.handleChange} id="purposeOfTraining" className="input-bf" required>
                                        <option value="">Select</option>
                                        {AppConfig.purposeList.map((purpose) => {
                                            return <option key={purpose} value={purpose}>{purpose}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        
                        {this.state.isFormEditable ? 
                            <button type="submit" className="button-bf m-2">Save</button>
                            : 
                            <button type="submit" onClick={this.moveToNextScreen} className="button-bf m-2">Next</button>
                            }
                        </form>
                    </div>

                </section>
            </div>

        )
    }
}






class SecondScreen extends React.Component {
    //amunt - amount paid by user
    constructor(props) {
        super(props)
        this.state = {
            discount: 0,
            remarks: "",
            receivedBy: "",
            showWarn: false,
            warnMsg: "",
            'shouldShowModal': false,
            'modalType': 'success',
            'modalMessage': 'Test',
            'redirectURL': '/admin/dashboard'
        }
    }

    handleChange = (event) => {
        const { id, value } = event.target;
        this.setState({
            [id]: value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        let pendingAmount = this.props.data.amountToBePaid - this.state.amount - this.state.discount
        if(pendingAmount > 0) {
            let msg = "The pending amount is " + pendingAmount + " Do you want to continue?"
            this.setState({
                showWarn: true,
                warnMsg: msg,
                isLoading: false
            })
        } else {
            this.approveMemberServiceCall()
        }
    }

    showModal = (modalType, modalMessage, redirectURL) => {
        this.setState({modalType: modalType, modalMessage: modalMessage, shouldShowModal: true, redirectURL: redirectURL})
    }

    hideModal = () => {
        this.setState({shouldShowModal: false})
    }
    hideWarn = () => {
        this.setState({showWarn: false})
    }

    setLoading = (value) => {
        this.setState({ isLoading: value })
    }

    

    approveMemberServiceCall = () => {
        this.setLoading(true)
        this.hideWarn()
        let pendingAmount = this.props.data.amountToBePaid - this.state.amount - this.state.discount
        let postJson = {
            "membershipId": this.props.data.membershipId,
            "pendingAmount": pendingAmount,
            "amount": this.state.amount,
            "discount": this.state.discount,
            "modeOfPayment": this.state.modeOfPayment,
            "receivedBy": this.state.receivedBy,
            "remarks": this.state.remarks,
            "gymMemberId": this.props.data.gymMember,
            "dateOfPayment": this.state.dateOfPayment
        }
        
        MainServices.postRequest("/admin/approve-member", postJson).then(
            res => {
                
                this.setLoading(false)
                let reurl = "/admin/dashboard/transaction/" + res.transId
                // this.props.showModal(this.props.success, "Payment Received", reurl)
                this.showModal('success', "Member approved!", reurl)
            },
            error => {
                this.showModal('failure', error)
                this.setLoading(false)
                // setIsLoading(false)
            })
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div>
                    
                    <Loader />
                </div>

            )
        }
        return (
            <div>
                
                <InfoModalOkBtnComponent
                    showModal={this.state.showWarn}
                    modalType='failure'
                    modalMessage={this.state.warnMsg}
                    hideModal={this.hideWarn}
                    btnTxt = "Ok, Continue"
                    callback={this.approveMemberServiceCall}
                />

                <InfoModalComponent
                    showModal={this.state.shouldShowModal}
                    modalType={this.state.modalType}
                    modalMessage={this.state.modalMessage}
                    hideModal={this.hideModal}
                    redirectURL={this.state.redirectURL}
                />
                <section className="">
                    <div className="w-full lg:w-7/12 px-4 mx-auto pt-6">

                        <div className="p-4 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0 bg-white">
                            <div className='mx-2'>
                                <h1>Name: {this.props.data.name}</h1>
                                <h1>Date Of Birth: {getStringFromDate(this.props.data.dateOfBirth)}</h1>
                                <h1>Membership End Date: {getStringFromDate(this.props.data.membershipEndDate)}</h1>

                                <h1>Amount to be paid: {this.props.data.amountToBePaid}</h1>
                            </div>
                            
                            <form onSubmit={this.handleSubmit}>
                                {/* Amount paid by user */}
                                <TextFieldControlledComponent changeFn={this.handleChange} id="amount" text="Amount Paid by user" type="number" isRequired={true} value={this.state["amount"]} isEditable={true} />

                                {/* discount */}
                                <TextFieldControlledComponent changeFn={this.handleChange} id="discount" text="Discount" type="number" isRequired={false} value={this.state["discount"]} isEditable={true} />

                                {/* remarks */}
                                <TextFieldControlledComponent changeFn={this.handleChange} id="remarks" text="Remarks" type="text" isRequired={false} value={this.state["remarks"]} isEditable={true} />

                                {/*mode of payment */}
                                <div className="p-2">
                                    <label for="modeOfPayment" className="input-label">Payment Mode *</label>
                                    <div className='flex'>
                                        <select onChange={this.handleChange} id="modeOfPayment" className="input-bf" required>
                                            <option value="">Select</option>
                                            <option value="CASH">Cash</option>
                                            <option value="UPI">UPI</option>
                                        </select>


                                    </div>
                                </div>

                                <TextFieldControlledComponent changeFn={this.handleChange} id="dateOfPayment" text="Date of payment" type="date" isRequired={true} value={this.state["dateOfPayment"]} isEditable={true} />

                                {/* receieved by */}
                                {/* <div className="p-2">
                                    <label for="height" className="input-label">Receieved By *</label>
                                    <div className='flex'>
                                        <select onChange={this.handleChange} id="receivedBy" className="input-bf" required>
                                            <option value="">Select</option>
                                            {AppConfig.receivedBy.map((person) => {
                                                // 
                                                return <option key={person} value={person}>{person}</option>
                                            })}
                                        </select>


                                    </div>
                                </div> */}

                                <button type="submit" className="button-bf">Confirm Member</button>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}


const ApproveMemberDetailComponentPrivate = NavigationBarHOC(ApproveMemberDetailComponentPrivateInner)

export default function ApproveMemberDetailComponent(props) {
    const location = useLocation()
    return <ApproveMemberDetailComponentPrivate data={location.state} />
}
import React from 'react';
import { useLocation } from 'react-router-dom';
import { MainServices } from '../../../../services/MainServices'
import TextFieldComponent from '../../../../CommonComponents/InputFieds/TextField';
import NavBarComponent from '../../../../CommonComponents/NavBarComponent';
import { BaseLogicHOC } from '../../../HOC/BaseLogicHOC';
import { getStringFromDate } from '../../../../helpers/Functions/DateToString';
import AppConfig from '../../../../AppConfig';
import InfoModalOkBtnComponent from '../../../../CommonComponents/InfoModal/InfoModalOkBtnComponent';
import { NavigationBarHOC } from '../../../HOC/NavigationBarHOC';
import { TableColumn, TransactCardForMobile } from '../../../../CommonComponents/Transactions/TransactComponent';

class RollOverDetailComponentInner extends React.Component {
    constructor(props) {
        super(props)
        this.state= {
            "typeOfTraining": '',
            showScreen: false,
            discount: 0,
            remarks: "",
            receivedBy: "",
            showWarn: false,
            warnMsg: "",
            "duration": null
        }
        this.durationArr = []
        this.timeSlotData = [
            // { value: '6 am - 7 am', label: '6 am - 7 am' },
            // { value: 'strawberry', label: 'Strawberry' },
            // { value: 'vanilla', label: 'Vanilla' },
        ]
        this.height = []
        this.setupTimeSlots()
        this.setupHeight()
    }
    componentDidMount() {
        this.fetchPricing()
        this.props.setSelectedComp(7)
        
    }

    
    setupTimeSlots = () => {
        for (let i = 6; i < 12; i++) {
            let val = i + " am - " + (i + 1) + " am"
            let data = { value: val, label: val }
            this.timeSlotData.push(data)
            if (i == 11) {

                let data2 = { value: "11:30 am - 12:30 pm", label: "11:30 am - 12:30 pm" }
                this.timeSlotData.push(data2)
            } else {
                let val2 = i + ":30 am - " + (i + 1) + ":30 am"
                let data2 = { value: val2, label: val2 }
                this.timeSlotData.push(data2)
            }
        }
        this.timeSlotData.push({ value: "12 pm - 1 pm", label: "12 pm - 1 pm" },)
        this.timeSlotData.push({ value: "12:30 pm - 1:30 pm", label: "12:30 pm - 1:30 pm" },)

        for (let i = 1; i < 10; i++) {
            let val = i + " pm - " + (i + 1) + " pm"
            let data = { value: val, label: val }
            this.timeSlotData.push(data)
            if (i == 9) {

            } else {
                let val2 = i + ":30 pm - " + (i + 1) + ":30 pm"
                let data2 = { value: val2, label: val2 }
                this.timeSlotData.push(data2)
            }
        }
    }

    setupHeight = () => {
        for (let i = 100; i < 216; i++) {
            let data = i
            this.height.push(data)
        }
    }

    handleChange = (event) => {

        const { id, value } = event.target;
    if(id === 'duration') {
        for(let i=0; i<this.pricingArrForSelectedTraining.length; i++) {
            
            
            let pricing = this.pricingArrForSelectedTraining[i]
            if(pricing._id === value) {
                this.amountToBePaid = pricing.price
            }
        }
        
    }
      
        this.setState({
            [id]: value
        })


    }


    fetchPricing = ( ) => {
        
        MainServices.getRequest("/admin/get-pricing").then(
            res => {
                
                this.data = res
                this.setState({showScreen: true})
                this.props.hideLoader()

            },
            error => {
                
                this.props.showModal(this.props.failure, error, null)
                this.props.hideLoader()
            })
    }

    handleSelectTraining = (event) => {
        let val = event.target.value
        let selectedTraining = this.data.filter((obj) => (obj._id == val))
        this.pricingArrForSelectedTraining = selectedTraining[0].pricing
        
        this.setState({
            "typeOfTraining": val,
            "duration": ''
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        let pendingAmount = this.amountToBePaid - this.state.amount - this.state.discount
        
        if(pendingAmount > 0) {
            let msg = "The pending amount is " + pendingAmount + " Do you want to continue?"
            
            this.props.showWarn(msg, this.renewMemberServiceCall)
            // this.setState({
            //     showWarn: true,
            //     warnMsg: msg
            // })
            // this.renewMemberServiceCall()
        } else {
            this.renewMemberServiceCall()
        }
    }

    renewMemberServiceCall = () => {
        // alert("called asda")
        this.props.hideWarn()
        this.props.showLoader()
        
        let pendingAmount = this.amountToBePaid - this.state.amount - this.state.discount
        let postJson = {
            "memberId": this.props.data.member._id,
            "typeOfTraining": this.state.typeOfTraining,
            "dateOfJoining": this.state.dateOfJoining,
            "duration": this.state.duration,
            "timeSlot": this.state.timeSlot,

            "pendingAmount": pendingAmount,
            "amount": this.state.amount,
            "discount": this.state.discount,
            "modeOfPayment": this.state.modeOfPayment,
            "receivedBy": this.state.receivedBy,
            "remarks": this.state.remarks
        }
        
        MainServices.postRequest("/admin/rollover/rollover-submit", postJson).then(
            res => {
                
                this.props.hideLoader()
                let reurl = "/admin/dashboard/transaction/" + res.transId
                this.props.showModal('success', "Membership Rollover success", reurl)
                // this.showModal('success', "Member Renewd!")
            },
            error => {
                this.props.showModal('failure', error)
                this.props.hideLoader()
                // setIsLoading(false)
            })
    }

    hideWarn = () => {
        this.setState({showWarn: false})
    }


    render() {
        if(!this.state.showScreen) {
            return null
        }
        let member = this.props.data.member
        return (
            <div>
                
                <InfoModalOkBtnComponent
                    showModal={this.state.showWarn}
                    modalType='failure'
                    modalMessage={this.state.warnMsg}
                    hideModal={this.hideWarn}
                    btnTxt = "Ok, Continue"
                    callback={this.renewMemberServiceCall}
                />
                <section className="">
                    <div className="main-card">
                        <div className='header-label'>
                            Rollover
                        </div>
                        <div className='medium-text'>
                            {member.gymMembership.pendingAmount > 0 ? <div> 
                                ⚠ There is a previous pending amount of {member.gymMembership.pendingAmount} ⚠
                            </div> : null}
                        </div>
                    
                        <div className='flex m-2 space-x-2'>
                            <img className='w-36 h-36 object-cover flex-none' src={member.media.photo} />


                            <div className='space-y-2'>
                                <h1 className='header-label'>Name: {member.name}</h1>
                                <h5 className='normal-text'>Client Code: {member.clientCode}</h5>
                                <h5 className='normal-text'>Mobile Number: {member.mobileNumber}</h5>
                                <h5 className='normal-text'>Date of Birth: {getStringFromDate(member.dateOfBirth)}</h5>
                            </div>
                        </div>
                            
                        <hr />
                                <div className='py-4 space-y-2'>
                                    <h5 className='normal-text'>Existing type of training: {member.gymMembership.typeOfTraining.typeOfTraining}</h5>
                                    <h5 className='normal-text'>Existing duration: {member.gymMembership.duration.duration}</h5>
                                    <h5 className='normal-text'>Existing membership joining date: {getStringFromDate(member.gymMembership.dateOfJoining)}</h5>
                                    <h5 className='normal-text'>Existing membership end date: {getStringFromDate(member.gymMembership.membershipEndDate)}</h5>

                                </div>

                        <hr/>
                        <h5 className="medium-text">Transaction History</h5>
                                <div className="sm:block md:hidden">
                                    only for sm mobile
                                    {
                                                member.gymMembership.transactions.map( (trasact) => {
                                                    return <TransactCardForMobile data={trasact} />
                                                })
                                            }
                                </div>
                                <div className="m-4 overflow-x-auto shadow-md sm:rounded-lg">
                                    <table className="hidden md:block table-auto text-sm text-left">
                                        <thead className="text-xs  uppercase bg-gray-50">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    Date
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Payment Mode
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Received By
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Remarks
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                member.gymMembership.transactions.map( (trasact) => {
                                                    return <TableColumn data={trasact} />
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                        <hr />

                        <div className='m-2 space-y-2'>
                            <form onSubmit={this.handleSubmit}>
                                {/* Type of training */}
                                <div className="mb-6 p-2">
                                    <label for="height" className="input-label">Type of training</label>

                                    <select
                                        onChange={this.handleSelectTraining}
                                        id="typeOfTraining" className="input-bf" required>
                                        <option value="">Select type of Training</option>
                                        {this.data.map((trainingType) => {
                                            
                                            return <option key={trainingType.id} value={trainingType._id}>{trainingType.typeOfTraining}</option>
                                        })}
                                    </select>
                                </div>

                                {/*  duration */}
                                {this.state.typeOfTraining == '' ? <></> :
                                    <div className="mb-6 p-2">
                                        <label for="height" className="input-label">Duration</label>

                                        <select
                                            onChange={this.handleChange}
                                            id="duration" className="input-bf" value={this.state["duration"]} required>
                                            <option value="">Select Duration</option>
                                            {this.pricingArrForSelectedTraining.map((pricing) => {

                                                return <option key={pricing.id} value={pricing._id}>{pricing.duration} Cost: {pricing.price}</option>
                                            })}
                                        </select>
                                    </div>
                                }


                                {/* dateOfJoining */}
                                <TextFieldComponent changeFn={this.handleChange} id="dateOfJoining" text="Date of joining" type="date" isRequired={true} />

                                {/* Time Slot */}
                                <div className="mb-6 p-2">
                                    <label for="mobile" className="input-label">Time Slot</label>
                                    {/* <CreatableSelect isClearable options={this.timeSlotData} /> */}

                                    <select onChange={this.handleChange} id="timeSlot" className="input-bf">
                                        <option value="">Select timeslot</option>
                                        {this.timeSlotData.map((height) => {
                                            // 
                                            return <option key={height.label} value={height.label}>{height.label}</option>
                                        })}
                                    </select>
                                </div>

                                <hr />
                                <h5 className='medium-text'>Payment details</h5>

                                {/* Amount paid by user */}
                                <TextFieldComponent changeFn={this.handleChange} id="amount" text="Amount Paid by user" type="number" isRequired={true}  />

                                {/* discount */}
                                <TextFieldComponent changeFn={this.handleChange} id="discount" text="Discount" type="number" isRequired={false}  />

                                {/* remarks */}
                                <TextFieldComponent changeFn={this.handleChange} id="remarks" text="Remarks" type="text" isRequired={false}  />

                                {/*mode of payment */}
                                <div className="p-2">
                                    <label for="modeOfPayment" className="block text-sm font-medium text-gray-900 dark:text-white pb-2">Payment Mode *</label>
                                    <div className='flex'>
                                        <select onChange={this.handleChange} id="modeOfPayment" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                                            <option value="">Select</option>
                                            <option value="CASH">Cash</option>
                                            <option value="UPI">UPI</option>
                                        </select>


                                    </div>
                                </div>

                                {/* receieved by */}
                                {/* <div className="p-2">
                                    <label for="height" className="block text-sm font-medium text-gray-900 dark:text-white pb-2">Receieved By *</label>
                                    <div className='flex'>
                                        <select onChange={this.handleChange} id="receivedBy" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                                            <option value="">Select</option>
                                            {AppConfig.receivedBy.map((person) => {
                                                // 
                                                return <option key={person} value={person}>{person}</option>
                                            })}
                                        </select>


                                    </div>
                                </div> */}

                                <button type="submit" className="button-bf">Renew Member</button>
                            </form>

                        </div>


                    </div>
                </section>
            </div>
        )
    }

}

const RollOverDetailComponentBase = BaseLogicHOC(RollOverDetailComponentInner);
const RollOverDetailComponent = NavigationBarHOC(RollOverDetailComponentBase)

export default function RollOverDetailComponentWrapper(props) {
    const location = useLocation()
    return <RollOverDetailComponent data={location.state} {...props} />
}

let receivedBy = ["Anil", "Azhar"]
let purposeList = ["Weight gain", "Weight loss", "Be fit", "Shape up", "Muscle gaining/Bulking", "Muscle conditioning/strengthening."]
const AppConfig = {
    // "baseUrl": "http://192.168.29.16:5000",
    receivedBy: receivedBy,
    "purposeList": purposeList,
    // "baseUrl": "https://tes-tqxf.onrender.com",
    "AppVersion": "1.06 (dev)",
    "baseUrl": "https://api.beyondfitnessandgym.com"
}

module.exports = AppConfig
import React from 'react';
import { JsonToExcel, exportToExcel } from "react-json-to-excel";
import { BaseLogicHOC } from '../../../HOC/BaseLogicHOC'
import TextFieldComponent from '../../../../CommonComponents/InputFieds/TextField'
import { MainServices } from '../../../../services/MainServices';
import { getStringFromDate } from '../../../../helpers/Functions/DateToString';
import { JsonToTable } from "react-json-to-table";
import { NavigationBarHOC } from '../../../HOC/NavigationBarHOC';
import { Link } from "react-router-dom";
import ManageAuth from '../../../../services/admin/ManageAuth';

class ReportsDashboardComponentPrivate extends React.Component {
    constructor(props) {
        super(props)
        console.log('🚀🚀🚀=== props ReportsDashboardComponent.js [14] ===', props);
        this.props.setSelectedComp(5)
        this.auth = ManageAuth.getInstance()
    }

    componentDidMount() {
        this.role = this.auth.getRole()
        console.log('🚀🚀🚀=== this.role ReportsDashboardComponent.js [22] ===', this.role);
    }

    


    render() {

        return (
            <div className=''>
                <section className='main-card p-2'>

                <Link to="membership" state={{member: this.props.member}}><button className='bg-white text-black p-2 m-2 rounded font-medium'> <button className='button-bf'>Membership Report</button></button></Link>
                
                {
                    this.role === 'superAdmin' ? 
                    <Link to="transactions" state={{member: this.props.member}}><button className='bg-white text-black p-2 m-2 rounded font-medium'> <button className='button-bf'>Transaction Report</button></button></Link>
                    :
                    null
                }
                
                  
                   
                </section>
            </div>
        )
    }
}

const ReportsDashboardComponent = NavigationBarHOC(BaseLogicHOC(ReportsDashboardComponentPrivate));

export default ReportsDashboardComponent
import React from 'react';
import { JsonToExcel, exportToExcel } from "react-json-to-excel";
import { BaseLogicHOC } from '../../../HOC/BaseLogicHOC'
import TextFieldComponent from '../../../../CommonComponents/InputFieds/TextField'
import { MainServices } from '../../../../services/MainServices';
import { getStringFromDate } from '../../../../helpers/Functions/DateToString';
import { JsonToTable } from "react-json-to-table";
import { NavigationBarHOC } from '../../../HOC/NavigationBarHOC';



class OldMebershipReportComponentPrivate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            res: null,
            pricingData: null
        }
        this.props.setSelectedComp(5)
    }

    

   

    handleChange = (event) => {

        const { id, value } = event.target;
        this.setState({
            [id]: value
        })

    }

    handleSubmit = (e) => {
        e.preventDefault()
        let postJson = {
            startDate: this.state.startDate,
            endDate: this.state.endDate
        }
        this.props.showLoader()
        MainServices.postRequest('/admin/mebership-report', postJson).then(
            res => {
                this.setState({
                    res: res
                })
                let sheet1data = []
                let sheet2data = []
                for (let i = 0; i < res.length; i++) {
                    let data = res[i]
                    
                    let json = {
                        "Client Code": data.gymMember.clientCode,
                        "Name": data.gymMember.name,
                        "Time Slot": data.timeSlot,
                        "Membership Type": data.membershipType,
                        "Type of training": data.typeOfTraining.typeOfTraining,
                        "Duration": data.duration.duration,
                        "Price": data.duration.price,
                        "Discount": data.discount,
                        "Total Amount Paid": data.totalAmountPaid,
                        "Date of Joining": getStringFromDate(data.dateOfJoining),
                        "Membership End Date": getStringFromDate(data.membershipEndDate),
                        "Mobile Number": data.gymMember.mobileNumber,
                        "Contacted For Reneawal": data.isContactedForReneawal,
                        "Is Renewed": data.hasBeenRenewed,
                        "System Remarks": data.systemRemarks
                    }
                    sheet1data.push(json)

                    
                    for (let k = 0; k < data.transactions.length; k++) {
                        let transData = data.transactions[k];
                        let json2 = {
                            "Client Code": data.gymMember.clientCode,
                            "Mode of Payment": transData.modeOfPayment,
                            "Payment Date": getStringFromDate(transData.dateOfPayment),
                            "Amount": transData.amount,
                            "Remarks": transData.remarks,
                            "Received By": transData.receivedBy  
                            }
                        sheet2data.push(json2);
                    }
                    
                }
                let excelArr = [{
                    sheetName:"Membership",
                    details: sheet1data
                  },{
                    sheetName:"Transactions",
                    details:sheet2data
                  }]
                this.setState({
                    excelArr: excelArr
                })
                this.props.hideLoader()
            },
            error => {
                this.props.hideLoader()
            }
        )
    }

    downloadExcel = () => {
        let fileName = "Membership Report From " + getStringFromDate(this.state.startDate) + " To " + getStringFromDate(this.state.endDate) + "Generated at " + new Date()
        exportToExcel(this.state.excelArr, fileName, true)
    }

    render() {

        return (
            <div className=''>
                <section className='main-card p-2'>
                    <form onSubmit={this.handleSubmit}>
                        <TextFieldComponent changeFn={this.handleChange} id="startDate" text="Start Date" type="date" isRequired={true} />
                        <TextFieldComponent changeFn={this.handleChange} id="endDate" text="End Date" type="date" isRequired={true} />
                        <button type="submit" className="button-bf">Get Report</button>
                    </form>

                    <br />
                    {this.state.res ?
                        <div className='my-4 py-2 border-t border-gray-800'>
                            <button className='button-bf' onClick={this.downloadExcel}>
                                Download Excel
                            </button>
                            {/* <JsonToTable json={this.state.excelArr} /> */}
                        </div>
                        :
                        null
                    }

                </section>
            </div>
        )
    }
}

class MebershipReportComponentPrivate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            res: null,
            pricingData: null
        }
        this.props.setSelectedComp(5)
        this.fetchData()
    }

    

   



    fetchData = () => {
        
        this.props.showLoader()
        MainServices.postRequest('/admin/new-mebership-report').then(
            res => {
                this.setState({
                    res: res
                })
                let sheet1data = []
                let sheet2data = []
                for (let i = 0; i < res.length; i++) {
                    let data = res[i]
                    
                    let json = {
                        "Client Code": data.clientCode,
                        "Name": data.name,
                        "Mobile Number": data.mobileNumber,
                        "Injuries": data.injuries,
                        "Time Slot": data.gymMembership.timeSlot,
                        "Height": data.height,
                        "Weight": data.weight,
                        "Purpose Of Training": data.purposeOfTraining,
                        "Date of Joining": getStringFromDate(data.gymMembership.dateOfJoining),
                        "Membership End Date": getStringFromDate(data.gymMembership.membershipEndDate),
                        "Type of training": data.gymMembership.typeOfTraining.typeOfTraining,
                        "Status": data.userStatus,
                        "Admin Comment": data.renewalComments,
                    }
                    sheet1data.push(json)

                    
                    
                }
                let excelArr = [{
                    sheetName:"Membership",
                    details: sheet1data
                  }]
                this.setState({
                    excelArr: excelArr
                })
                this.downloadExcel()
                this.props.hideLoader()
            },
            error => {
                this.props.hideLoader()
            }
        )
    }

    downloadExcel = () => {
        let fileName = "Membership Report Dump " + getStringFromDate(new Date())
        exportToExcel(this.state.excelArr, fileName, true)
    }

    render() {

        return (
            <div className=''>
                <section className='main-card p-2'>
                    

                    <br />
                    {this.state.res ?
                        <div className='my-4 py-2 border-t border-gray-800'>
                            <button className='button-bf' onClick={this.downloadExcel}>
                                Download Excel
                            </button>
                            {/* <JsonToTable json={this.state.excelArr} /> */}
                        </div>
                        :
                        null
                    }

                </section>
            </div>
        )
    }
}

const MebershipReportComponent = NavigationBarHOC(BaseLogicHOC(MebershipReportComponentPrivate));

export default MebershipReportComponent
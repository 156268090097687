import React, { useState } from 'react';
import ok from "../../assets/ok.gif"
import warning from "../../assets/error.gif"
import { Link } from 'react-router-dom';

class InfoModalComponent extends React.Component {

  constructor(props) {

    super(props)
    this.state = {
      redirectURL: null
    }
    this.hideModalInfo = this.hideModalInfo.bind(this)

  }

  componentDidMount() {
    this.setState({ redirectURL: this.props.redirectURL })
  }

  hideModalInfo() {
    this.props.hideModal()
  }


  render() {
    let imgDiv = <img src={ok} alt="loading..." />;
    let link = (<button href="" type="button"
      className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
      data-modal-hide="popup-modal"
      onClick={this.hideModalInfo}
    >
      <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
      <span className="sr-only">Close modal</span>
    </button>)
    if (this.props.redirectURL && this.props.redirectURL !== 'false') {
      link = (<Link to={this.props.redirectURL}>
        <button type="button"
          className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          data-modal-hide="popup-modal"
          onClick={this.hideModalInfo}
        >
          <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          <span className="sr-only">Close modal</span>
        </button>
      </Link>)
    }

    if (this.props.showModal == false) {
      return <div></div>
    }
    if (this.props.modalType == 'success') {
      imgDiv = <img src={ok} alt="loading..." />;
    } else {
      imgDiv = <img src={warning} alt="loading..." />;
    }
    return (

      <>
        <div
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/*content*/}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              {link}
              <div className="p-6 text-center">
                {imgDiv}

                {/* <svg aria-hidden="true" className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg> */}
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{this.props.modalMessage}</h3>

              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>

    )
  }
}

export default InfoModalComponent;
import { getStringFromDate } from "../../helpers/Functions/DateToString"
import React from "react";

export class TransactCardForMobile extends React.Component {
    render() {
        return (
            <div className="bg-white border-b">
                <div className="mobile-transcat-cell">
                    Date: {getStringFromDate(this.props.data.dateOfPayment)}
                </div>
                <div className="mobile-transcat-cell">
                    Mode of Payment: {this.props.data.modeOfPayment}
                </div>
                <div className="mobile-transcat-cell">
                    Receieved By: {this.props.data.receivedBy}
                </div>
                <div className="mobile-transcat-cell">
                    Remarks: {this.props.data.remarks}
                </div>
                <div className="mobile-transcat-cell text-rightpx-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    Amount: {this.props.data.amount}
                </div>
            </div>
        )
    }
}


export class TableColumn extends React.Component {
    render() {
        return (
            <tr className="bg-white border-b hover:bg-gray-50">
                <th scope="row" className="px-6 py-4">
                    {getStringFromDate(this.props.data.dateOfPayment)}
                </th>
                <td className="px-6 py-4">
                    {this.props.data.modeOfPayment}
                </td>
                <td className="px-6 py-4">
                    {this.props.data.receivedBy}
                </td>
                <td className="px-6 py-4">
                    {this.props.data.remarks}
                </td>
                <td className="px-6 py-4 text-rightpx-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {this.props.data.amount}
                </td>
            </tr>
        )
    }
}

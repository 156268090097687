
import './App.css';
import AdminLoginComponent from './components/admin/AuthComponents/AdminLoginComponent';
import AdminSignupComponent from './components/admin/AuthComponents/AdminSignupComponent';
import AdminDashboardComponent from './components/admin/DashComponents/AdminDashboardComponent';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  BrowserRouter,
  Navigate
} from 'react-router-dom';
import PrivateRoutes from './helpers/PrivateRoutes';
import React from 'react';
import NavBarComponent from './CommonComponents/NavBarComponent';
import AddNewMebershipComponent from './components/admin/MemberComponents/AddNewMebershipComponent';
import ApproveMemberDetailComponent from './components/admin/DashComponents/ApproveMembers/ApproveMemberDetailComponent';
import ReceivePendingPaymentComponentWrapper from './components/admin/DashComponents/PendingComponents/ReceivePendingPaymentComponent';
import RenewMemberDetailComponentWrapper from './components/admin/DashComponents/RenewalComponents/RenewMemberDetailComponent';
import UpdateMembershipComponent from './components/admin/DashComponents/UpdateMembershipDetails/UpdateMembershipComponent';
import ManageAuth from './services/admin/ManageAuth';
import ApproveMembersComponent from './components/admin/DashComponents/ApproveMembers/ApproveMembersComponent';
import PendingPaymentComponent from './components/admin/DashComponents/PendingComponents/PendingPaymentComponent';
import RenewalComponent from './components/admin/DashComponents/RenewalComponents/RenewalComponent';
import GetMembershipComponent from './components/admin/DashComponents/UpdateMembershipDetails/GetMembershipComponent';

import UpdatePricingComponent from './components/admin/DashComponents/UpdatePricingComponent';
import MemberSuccessComponent from './components/admin/MemberComponents/MemberSuccessComponent';
import RollOverListComponent from './components/admin/DashComponents/Rollover/RollOverListComponent';
import RollOverDetailComponentWrapper from './components/admin/DashComponents/Rollover/RollOverDetailComponent';
import ReportsDashboardComponent from './components/admin/DashComponents/Reports/ReportsDashboardComponent';
import ReportTransactionComponent from './components/admin/DashComponents/Reports/ReportComponent';
import MebershipReportComponent from './components/admin/DashComponents/Reports/MebershipReportComponent';
import TransactionReceiptComponent from './components/admin/DashComponents/TransactionReceipt/TransactionReceiptComponent';
import OneTimeComponent from './components/admin/MemberComponents/OneTimeComp';



class App extends React.Component {




  constructor(props) {
    super(props)
    // this.state = {
    //   authInstance: ManageAuth.getInstance(),
    // }
    this.authInstance = ManageAuth.getInstance()
    this.state = {
      isLoggedIn: this.authInstance.isUserLoggedIn()
    }
  }

  clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };
  

  componentDidMount() {

    this.authInstance.addObserver(this.logOut)
    this.clearCacheData()

  }

  logOut = () => {
    
    window.location.reload(false);
  }



  componentWillUnmount() {
    this.authInstance.removeObserver(this.logOut)
    
  }




  render() {

    return (
      <div>


        <BrowserRouter>
          <Routes>
            <Route exact path='' element={< AddNewMebershipComponent />}></Route>
            <Route exact path="/success" element={<MemberSuccessComponent />} />
            {/* <Route
                    path=""
                    element={ <Navigate to="/admin/login" /> }
                /> */}
            {/* <Route exact path='' element={< HomePage />}></Route> */}
            <Route exact path='/admin/login' element={< AdminLoginComponent />}></Route>
            <Route exact path='/admin/signup' element={< AdminSignupComponent />}></Route>
            
            {/* Private Admin Routes */}
            <Route element={<PrivateRoutes />}>
              <Route exact path='/admin/dashboard' element={< AdminDashboardComponent />}></Route>
              {/* Approve Routes */}
              <Route exact path='/admin/dashboard/approve-member' element={< ApproveMembersComponent />}></Route>
              <Route exact path="/admin/dashboard/approve-member/approve" element={<ApproveMemberDetailComponent />} />

              {/*  Pending Payment Routes */}
              <Route exact path='/admin/dashboard/pending' element={< PendingPaymentComponent />}></Route>
              <Route exact path="/admin/dashboard/pending/receive-pending" element={<ReceivePendingPaymentComponentWrapper />} />
              

              {/* Renewal */}
              <Route exact path='/admin/dashboard/renewal' element={< RenewalComponent />}></Route>
              <Route exact path="/admin/dashboard/renewal/renew-member" element={<RenewMemberDetailComponentWrapper />} />

              {/* Rollover */}
              <Route exact path='/admin/dashboard/rollover' element={< RollOverListComponent />}></Route>
              <Route exact path="/admin/dashboard/rollover/detail" element={<RollOverDetailComponentWrapper />} />
              
              {/* Update Membership */}
              <Route exact path='/admin/dashboard/update' element={< GetMembershipComponent />}></Route>
              <Route exact path="/admin/dashboard/update/update-member-details" element={<UpdateMembershipComponent />} />


              {/* Report */}
              <Route exact path='/admin/dashboard/report' element={< ReportsDashboardComponent />}></Route>
              <Route exact path='/admin/dashboard/report/transactions' element={< ReportTransactionComponent />}></Route>
              <Route exact path='/admin/dashboard/report/membership' element={< MebershipReportComponent />}></Route>

              {/*  gym pricing */}
              <Route exact path='/admin/dashboard/update-pricing' element={< UpdatePricingComponent />}></Route>

              {/* Transaction receipt */}
              <Route path="/admin/dashboard/transaction/:id" element={< TransactionReceiptComponent />}></Route>

              <Route path="/admin/onetime" element={< OneTimeComponent />}></Route>

              



              
            </Route>

          </Routes>
        </BrowserRouter>


      </div>
    );
  }
}

function HomePage() {
  return (
    <h1 className="text-3xl font-bold underline">
      Hello world!
    </h1>
  )
}


// class Loader12 extends React.Component {

//   constructor(props) {
//       super(props)
//       this.state = {
//         isLoading: false
//       }

//   }

//   listenCall = (param) => {
//     this.setState((state, props) => ({
//       isLoading: param 
//     }));
//     
//   }

//   componentDidMount() {

//     let serv = MainServices1.getInstance()
//       serv.subscribe(this.listenCall)
//       // 
//       // 
//   }



//   render() {
//     if(this.state.isLoading) {
//       return (
//       <div role="status" className='fixed inset-0 bg-opacity-10 backdrop-blur-sm flex justify-center items-center'>

//                   <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
//                       <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
//                       <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
//                   </svg>
//                   <span className="sr-only">Loading...</span>
//               </div>
//       )
//     } else {
//      return <div></div>  
//     }

//   }
// }

export default App;

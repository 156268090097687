import { handleResponse } from "../helpers/handleResponse"
import { authHeader } from "../helpers/authHeader"
import AppConfig from "../AppConfig"
export const MainServices = {
    getRequest: getRequest,
    postRequest: postRequest,
    postRequestWithoutAuth: postRequestWithoutAuth,
    postRequest1: postRequest1,
   
}

function getRequest(url) {
    const requestHeader = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(AppConfig.baseUrl + url, requestHeader).then(handleResponse)
}

function postRequest(url, bodyObj) {
    const requestHeader = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(bodyObj)
    }
    
    return fetch(AppConfig.baseUrl + url, requestHeader).then(handleResponse)
}

function postRequestWithoutAuth(url, bodyObj) {
    const requestHeader = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyObj)
    }
    
    return fetch(AppConfig.baseUrl + url, requestHeader).then(handleResponse)
}


function postRequest1(url, bodyObj) {
    const requestHeader = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(bodyObj)
    }
    
    return fetch(AppConfig.baseUrl + url, requestHeader).then(handleResponse)
}


import React from 'react';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import InfoModalComponent from '../../../CommonComponents/InfoModal/InfoModalComponent';
import InfoModalOkBtnComponent from '../../../CommonComponents/InfoModal/InfoModalOkBtnComponent';
import Loader from '../../../helpers/Loader/loader';
import { MainServices } from '../../../services/MainServices';


function baseLogics(WrappedComponent) {

    return class extends React.Component {
        constructor(props) {
            super(props)
            this.state = {
                "isLoading": false,
                showWarn: false,
                warnMsg: "",
                'shouldShowModal': false,
                'modalType': 'success',
                'modalMessage': 'Test',
                'redirectURL': '/admin/dashboard'

            }
            this.success='success'
            this.failure='failure'
        }

        showWarn = (warnMsg) => {
            this.setState({ showWarn: true, warnMsg: warnMsg })
        }

        hideWarn = () => {
            this.setState({ showWarn: false })
        }
        showModal = (modalType, modalMessage, redirectURL) => {
            this.setState({ modalType: modalType, modalMessage: modalMessage, shouldShowModal: true, redirectURL: redirectURL })
        }
        hideModal = () => {
            
            this.setState({ shouldShowModal: false })
        }


        showLoader = () => {
            this.setState({ isLoading: true })
        }

        hideLoader = () => {
            this.setState({ isLoading: false })
        }

        render() {
            
            return (
                <>
                    {this.state.isLoading && <Loader />}
                    <InfoModalOkBtnComponent
                    showModal={this.state.showWarn}
                    modalType='failure'
                    modalMessage={this.state.warnMsg}
                    hideModal={this.hideWarn}
                    btnTxt = "Ok, Continue"
                    callback={this.approveMemberServiceCall}
                />

<InfoModalComponent
                    showModal={this.state.shouldShowModal}
                    modalType={this.state.modalType}
                    modalMessage={this.state.modalMessage}
                    hideModal={this.hideModal}
                    redirectURL={this.state.redirectURL}
                />
                    <WrappedComponent 
                    showLoader={this.showLoader}
                    hideLoader={this.hideLoader}
                    showWarn={this.showWarn}
                    hideWarn={this.hideWarn}
                    showModal={this.showModal}
                    hideModal={this.hideModal}
                    success={this.success}
                    failure={this.failure}  
                    {...this.props} >
                        
                    </WrappedComponent>
                </>
            )
        }
    }

}


class CameraComponentA extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            facingMode: FACING_MODES.USER
        }
    }
    componentDidMount() {
        // this.props.showLoader()
        // this.props.showModal(this.props.failure, "Testing from child", '/')
        
        this.fetchData()
    }

    fetchData = () => {
        
        this.props.showLoader()
        MainServices.getRequest("/admin/get-pricing").then(
            res => {
                
                this.setState({ "data": res })
                this.props.hideLoader()
                this.props.showModal('success', "TEST", '')

            },
            error => {
                
                this.props.hideLoader()
            })
    }


    handleTakePhoto = (dataUri) => {
        // Do stuff with the photo...
        

    }

    handleTakePhotoAnimationDone = (dataUri) => {
        // Do stuff with the photo...
        
        alert(121, dataUri)
        this.props.callback(dataUri)
    }

    handleCameraError = (error) => {
        
    }

    handleCameraStart = (stream) => {
        
    }

    handleCameraStop = () => {
        
    }

    reverseCamera = () => {
        if (this.state.facingMode === FACING_MODES.USER) {
            this.setState({ facingMode: FACING_MODES.ENVIRONMENT })
        } else {
            this.setState({ facingMode: FACING_MODES.ENVIRONMENT })
        }
    }
    render() {
        return (
            <div className=''>
                
                <h1>TEst</h1>
            </div>
        );
    }

}
const CameraComponent = baseLogics(CameraComponentA)

export default CameraComponent;
import React from 'react';
import InfoModalComponent from '../../../CommonComponents/InfoModal/InfoModalComponent';
import TextFieldComponent from '../../../CommonComponents/InputFieds/TextField';
import TextFieldControlledComponent from '../../../CommonComponents/InputFieds/TextFieldControlledComponent';
import Loader from '../../../helpers/Loader/loader';
import { MainServices } from '../../../services/MainServices';
import { NavigationBarHOC } from '../../HOC/NavigationBarHOC';
 
class UpdatePricingComponentInner extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            "isLoading": true,
            "data": []
        }
        this.props.setSelectedComp(6)
    }

    setLoading = (value) => {
        this.setState({ "isLoading": value })
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        
        this.setLoading(true)
        MainServices.getRequest("/admin/get-pricing").then(
            res => {
                
                this.setState({ "data": res })
                this.setLoading(false)

            },
            error => {
                
                this.setLoading(false)
            })
    }

    handleclick = (id) => {
        
    }

    updateData = (data) => {

    }


    render() {
        if (this.state.isLoading) {
            return (<Loader />)
        }
        return (
            <section className="">

                <div className="w-full lg:w-7/12 px-4 mx-auto pt-6">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6   ">
                        <h6 className="p-4">

                            {
                                this.state.data.map((training) => {
                                    return <TrainingCardComponent refresh={this.fetchData} typeOfTraining={training.typeOfTraining} pricing={training.pricing} />
                                })
                            }
                        </h6>
                    </div>
                </div>
            </section>

        )


    }
}

class TrainingCardComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            "editingEnabled": false,
            "editedValues": [],
            "isLoading": false,
            'shouldShowModal': false,
            'modalType': 'success',
            'modalMessage': 'Test',
        }
    }

    enableEditing = () => {
        let newPriceList = []

        for(let i=0 ; i<this.props.pricing.length; i++) {
            let data = this.props.pricing[i]
            let price = {
                "_id": data._id,
                "price": data.price,
            }
            newPriceList.push(price)
        }

        
        
        this.setState({
            "editedValues": newPriceList,
            "editingEnabled": true})
    }

    setLoading = (value) => {
        this.setState({"isLoading": value})
    }

    handleSave = () => {
        // let strly = JSON.stringify(this.state.editedValues.toString())
        let obj = {
            "arr": this.state.editedValues
        }

 
        
       this.setLoading(true)


        MainServices.postRequest1("/admin/update-pricing", obj).then(
            res => {
                
                this.setState({ "data": res })
                this.showModal('success', res.message)
                this.setLoading(false)

            },
            error => {
                
                this.showModal('failure', "Something went wrong")
                this.setLoading(false)
            })
    }

    updateEditedPrice = (index, price) => {
        let newVals = this.state.editedValues[index]
       
        // newVals.price = price
        // this.setState( (prevState) => {
        //     return {
        //         "editedValues": newVals
        //     }
        // })
        let editedValues = [...this.state.editedValues];     // create the copy of state array
        editedValues[index].price = price;                  //new value
        this.setState({ editedValues }, () => {
            
        });            //update the value
    }
    updateEditedDiscount = (index, discount) => {
        let editedValues = [...this.state.editedValues];     // create the copy of state array
        editedValues[index].discount = discount;                  //new value
        this.setState({ editedValues });
    }

    showModal = (modalType, modalMessage) => {
        this.setState({modalType: modalType, modalMessage: modalMessage, shouldShowModal: true})
    }
    hideModal = () => {
        this.props.refresh()
        this.setState({shouldShowModal: false})
        
    }
    
    render() {
        let saveBtn = <button className='button-bf' onClick={this.handleSave}>Save</button>;
        let infoModal = <InfoModalComponent
        showModal={this.state.shouldShowModal}
        modalType={this.state.modalType}
        modalMessage={this.state.modalMessage}
        hideModal={this.hideModal}
    />
    if(this.state.isLoading) {
        return <Loader />
    }
        if(this.state.editingEnabled) {
            
            return (
                <div className='bg-white shadow-lg rounded-lg m-4 p-2'>
                    {infoModal}
                <h1 className='font-bold'>{this.props.typeOfTraining}</h1>
                
                {
                
                this.props.pricing.map((data, index) => {
                    return (<div><TrainingCardDetailComponent className="m-2" editDisFn={this.updateEditedDiscount} editFn={this.updateEditedPrice} data={data} index={index} editingEnabled={this.state.editingEnabled} editedValues={this.state.editedValues[index]} />
                    <hr /></div>)
                })}
                <br className='bg-black'/>
                {this.state.editingEnabled ? saveBtn : <></>}
            </div>
            )
        }
        return (
            <div className='bg-white shadow-lg rounded-lg m-4 p-2'>
                {infoModal}
                <h1 className='font-bold'>{this.props.typeOfTraining}</h1>
                <button className="button-bf" onClick={this.enableEditing}>Edit</button>
                {this.props.pricing.map((data) => {
                    return <TrainingCardDetailComponent className="m-2" data={data} editingEnabled={this.state.editingEnabled} editedValues={this.state.editedValues} />
                })}
                <br className='bg-black'/>
                
                {this.state.editingEnabled ? saveBtn : <></>}
            </div>
        )
    }
}

class TrainingCardDetailComponent extends React.Component {
    componentDidMount() {
        
        
    }

    handleEdit = (event) => {
        
        this.props.editFn(this.props.index, event.target.value)
    }

    handleDiscountEdit = (event) => {
        this.props.editDisFn(this.props.index, event.target.value)
    }

     
    render() {
        if(this.props.editingEnabled) {
            return (
                <div className='my-4'>
                    <h1 className='input-label '>
                        {this.props.data.duration}
                    </h1>
                    
                    <h2 className='input-label'>
                        Existing Price: {this.props.data.price}
                    </h2>
                    
                    {/* New Price: */}
                    {/* <TextFieldComponent /> */}
                    <TextFieldControlledComponent changeFn={this.handleEdit} id="price" text="Enter new Price" type="number" isRequired={false} value={this.props.editedValues.price} isEditable={true}  />
                    {/* <TextFieldComponent changeFn={this.handleEdit} id="price" text="Enter new Price" type="number" isRequired={false} />  */}
                    {/* <input id="price" type="text"  value={this.props.editedValues.price} onChange={this.handleEdit}></input> */}
                    <div>

                    </div>
                    
                </div>
            )
        }
        return (
            <div className='my-4'>
                <h1 className='medium-text '>
                    {this.props.data.duration}
                </h1>
                <h2 className='input-label'>
                    {this.props.data.price}
                </h2>
                
                {/* {this.props.data} */}
            </div>
        )
    }
}

const UpdatePricingComponent = NavigationBarHOC(UpdatePricingComponentInner)
export default UpdatePricingComponent;
import React from 'react';
import  { getStringFromDate }  from '../../../../helpers/Functions/DateToString';
import Loader from '../../../../helpers/Loader/loader';
import { MainServices } from '../../../../services/MainServices'
import  TextFieldComponent  from '../../../../CommonComponents/InputFieds/TextField';
import { Link } from "react-router-dom";
import UserCardComponent from '../../../../CommonComponents/UserCards/UserCardComponent';
import { NavigationBarHOC } from '../../../HOC/NavigationBarHOC';


class ApproveMembersComponentInnner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            "res": null,
            "isLoading": true
        }
    }

    componentDidMount() {
        this.props.setSelectedComp(1)
        this.fetchData()
        
    }

    handleclick = (id) => {
        
    }

    setLoader = (value) => {
        this.setState({isLoading: value})
    }

    fetchData = () => {
        MainServices.postRequest("/admin/get-pendingMembers").then(
            res => {
                console.log('🚀🚀🚀=== res ApproveMembersComponent.js [37] ===', res);
                this.setState({ res })
                this.setLoader(false)
            },
            error => {
                this.setLoader(false)
                
                
            })
    }

    handleChange = (event) => {
        const { id, value } = event.target;
        this.setState({
            [id]: value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        this.setLoader(true)
        let postJson = {
            "mobileNumber": this.state.mobileNumber
        }
        MainServices.postRequest("/admin/get-pendingMembers", postJson).then(
            res => {
                
                this.setState({res})
                this.setLoader(false)

            },
            error => {
                
                this.setLoader(false)
            })
    }


    render() {
        if(this.state.isLoading) {
            
            return <Loader />
        }
       
        let errorState = <h1> No Pending Member found</h1>
        if (this.state.res) {
            return (
                <div>
                
                <section className="">
                    <div className="main-card">
                        <div className="flex flex-col min-w-0 break-words w-full mb-6 border-0 ">
                            <div className='mb-6 '>
                                <form onSubmit={this.handleSubmit}>
                                <TextFieldComponent changeFn={this.handleChange} id="mobileNumber" text="Mobile Number" type="number" isRequired={true} />
                                <button type="submit" className="text-white bg-gray-700 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Search</button>
                                </form>
                            </div>
                            
                            {this.state.res.length === 0 ? errorState : this.state.res.map((member) => {
                                console.log('🚀🚀🚀=== member ApproveMembersComponent.js [96] ===', member);
                                return (<Link to="approve" state={{member: member}}>
                                    <UserCardComponent member={member} />
                                    </Link>) 
                            })}
                            

                        </div>
                    </div>
                </section>
                </div>
            )
        }
        return (
            <div className=''>
                


            </div>

        )


    }
}

const ApproveMembersComponent = NavigationBarHOC(ApproveMembersComponentInnner)
export default ApproveMembersComponent

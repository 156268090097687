import React from 'react';
import { Link } from 'react-router-dom';
import TextFieldComponent from '../../../../CommonComponents/InputFieds/TextField';
import NavBarComponent from '../../../../CommonComponents/NavBarComponent';
import UserCardComponent from '../../../../CommonComponents/UserCards/UserCardComponent';
import Loader from '../../../../helpers/Loader/loader';
import { MainServices } from '../../../../services/MainServices';
import { NavigationBarHOC } from '../../../HOC/NavigationBarHOC';




class GetMembershipComponentInner extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            "res": [],
            "isLoading": false,
            "errorStateCheck": false
        }
        this.props.setSelectedComp(4)
    }

    

    handleclick = (id) => {
        
    }

    setLoader = (value) => {
        this.setState({ isLoading: value })
    }

    handleChange = (event) => {
        const { id, value } = event.target;
        this.setState({
            [id]: value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        this.setLoader(true)
        let postJson = {
            "mobileNumber": this.state.mobileNumber
        }
        MainServices.postRequest("/admin/fetch-members", postJson).then(
            res => {
                
                this.setState({ res, errorStateCheck: true })
                this.setLoader(false)

            },
            error => {
                
                this.setLoader(false)
            })
    }


    render() {
        if (this.state.isLoading) {
            
            return <Loader />
        }
        let errorState 
        if(this.state.errorStateCheck) {
            if(this.state.res.length === 0) {
                errorState = <h1>No user found</h1>
            } else {
                errorState = (this.state.res.map((member) => {
                    
                    return (<Link to="update-member-details" state={{ member: member }}>
                        <UserCardComponent member={member} />
                    </Link>)
                }))
            }
        }
        

        return (
            <div>

                <section className="">
                    <div className="main-card">
                        <div className="flex flex-col min-w-0 break-words w-full mb-6 border-0 ">
                            <div className='mb-6 '>
                                <form onSubmit={this.handleSubmit}>
                                    <TextFieldComponent changeFn={this.handleChange} id="mobileNumber" text="Mobile Number" type="number" isRequired={true} />
                                    <button type="submit" className="text-white bg-gray-700 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Search</button>
                                </form>
                            </div>
                            {errorState}
                            

                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const GetMembershipComponent = NavigationBarHOC(GetMembershipComponentInner);
export default GetMembershipComponent
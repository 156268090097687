import React from "react";
import SignaturePad from 'react-signature-canvas'
import InfoModalComponent from '../InfoModal/InfoModalComponent';
export default class SignComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            'showModal': false,
            'modalType': 'success',
            'modalMessage': 'Test',
        }
        this.sigPad = {}
    }

    showInfoModal = (message, modalType) => {
        this.setState({
            'showModal': true,
            'modalType': modalType,
            'modalMessage': message,
        })
    }

    hideModal = () => {
        this.setState({
            'showModal': false
        })
    }


    trim = () => {
        let sign = this.sigPad.getTrimmedCanvas()
            .toDataURL('image/png')
        
        if (this.sigPad.isEmpty()) {
            
            this.showInfoModal("Please sign", 'failure')
            return
        } else {
            this.props.callback(sign)
        }

    }
    render() {
        return (
            <div>
                <InfoModalComponent
                    showModal={this.state.showModal}
                    modalType={this.state.modalType}
                    modalMessage={this.state.modalMessage}
                    hideModal={this.hideModal}
                />
                <h2>Please sign in the below box</h2>
                <div className='border-2'>

                    <SignaturePad canvasProps={{ className: "h-48 w-full" }}
                        ref={(ref) => { this.sigPad = ref }} />
                    <button type="button" className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                        onClick={this.clear}>Clear Sign</button>
                </div>
                <div>
                <button type="button" className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
            onClick={this.trim}>Submit</button>
                </div>
            </div>

        )
    }
}
import React from "react";

export function Icon1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      viewBox="0 0 52.917 52.917"
    >
      <circle
        cx="26.458"
        cy="26.458"
        r="12"
        fill="#fff"
        fillOpacity="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.27"
        paintOrder="stroke fill markers"
      ></circle>
      <path
        strokeWidth="0.519"
        d="M22.496 34.602c0-.384.467-1.12 1.038-1.637.8-.724 1.038-1.599 1.038-3.817 0-2.173-.147-2.731-.599-2.28-.411.413-.897.44-1.556.086-.755-.404-.959-1.123-.959-3.388 0-2.487.205-3.081 1.517-4.393 1.938-1.938 4.546-2.055 6.592-.295 1.13.971 1.487 1.83 1.747 4.19.285 2.592.195 3.062-.707 3.694-.76.532-1.216.573-1.719.157-.553-.46-.684.022-.684 2.512 0 2.756-.166 3.24-1.581 4.595-1.223 1.172-1.679 1.358-2.01.82-.301-.485-.537-.522-.785-.12-.493.797-1.332.72-1.332-.124zm3.976-4.102c-.144-.357-.262-.065-.262.648 0 .714.118 1.006.262.65.144-.358.144-.941 0-1.298zm.214-6.098c.722 0 1.558.464 1.941 1.078 1.032 1.652 2.172.36 2.172-2.462 0-3.862-2.962-5.835-6.405-4.267-1.511.689-2.417 2.485-2.417 4.793 0 2.432.948 3.2 2.34 1.892.605-.568 1.671-1.034 2.369-1.034zm-1.413-1.311c-.191-.498-.025-1.065.39-1.327 1.029-.65 1.763-.293 1.763.858 0 1.254-1.71 1.626-2.153.469z"
      ></path>
    </svg>
  );
}




export function Icon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="200"
      height="200"
      version="1.1"
      viewBox="0 0 52.917 52.917"
      xmlSpace="preserve"
    >
      <defs>
        <color-profile
          name="Black---White"
          xlinkHref="file:///Library/ColorSync/Profiles/Black%20&amp;%20White.icc"
        ></color-profile>
      </defs>
      <g>
        <circle
          cx="26.458"
          cy="26.458"
          r="12"
          fill="#fff"
          fillOpacity="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.27"
          paintOrder="stroke fill markers"
          stopColor="#000"
        ></circle>
        <g
          fill="#000"
          strokeWidth="0.265"
          transform="matrix(1.90023 0 0 1.90023 410.03 208.655)"
        >
          <path d="M-202.237-98.788c-.103-.166-.062-.427.09-.579.355-.356 1.184-.045 1.04.39-.148.441-.896.567-1.13.189z"></path>
          <path d="M-205.01-93.407c0-.101.302-.5.671-.885.37-.385.904-1.314 1.19-2.065.44-1.156.458-1.364.122-1.364-.217 0-.395.23-.395.513 0 .283-.12.587-.265.677-.291.18-.357-.641-.104-1.3.249-.65 2.124-.566 2.485.11.188.35.554.529 1.083.529.513 0 .736.104.621.29-.098.16-.581.243-1.074.185-.723-.084-.894-.013-.894.372 0 .262.191.476.426.476.35 0 .393.179.24.99-.217 1.161-.335 1.391-.711 1.391-.175 0-.214-.344-.105-.926.161-.861.126-.926-.5-.926-.37 0-.673.11-.673.244 0 .421-1.352 1.873-1.744 1.873-.205 0-.373-.083-.373-.184z"></path>
          <path d="M-202.234-92.902c1.817-.012 3.437-.105 3.6-.205.187-.116.233-.573.126-1.241-.206-1.292-.621-2.119-.959-1.91-.36.223-.31-.24.066-.617.366-.366.842-.43.629-.085-.08.128.039.6.263 1.048.255.512.377 1.337.326 2.215l-.08 1.4-3.638.047c-2.8.037-3.638-.03-3.638-.29z"></path>
        </g>
      </g>
    </svg>
  );
}


export function Icon3() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="200"
      height="200"
      version="1.1"
      viewBox="0 0 52.917 52.917"
      xmlSpace="preserve"
    >
      <defs>
        <color-profile
          name="Black---White"
          xlinkHref="file:///Library/ColorSync/Profiles/Black%20&amp;%20White.icc"
        ></color-profile>
      </defs>
      <g>
        <circle
          cx="26.458"
          cy="26.458"
          r="12"
          fill="#fff"
          fillOpacity="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.27"
          paintOrder="stroke fill markers"
          stopColor="#000"
        ></circle>
        <path
          fill="#000"
          strokeWidth="0.621"
          d="M23.43 34.804c-.181-.293-.058-1.48.274-2.636.332-1.157.603-3.708.603-5.668 0-3.284-.122-3.596-1.553-3.955-1.128-.283-1.553-.806-1.553-1.912 0-.857-.272-1.355-.621-1.139-.342.211-.622-.184-.622-.88 0-1.082.218-1.18 1.512-.689 1.06.404 1.602.353 1.815-.17.167-.41.325-.1.352.689.03.89-.21 1.275-.632 1.013-.423-.26-.597.02-.46.74.5 2.615 7.975 2.516 7.975-.105 0-.54-.28-.809-.621-.598-.342.211-.621-.185-.621-.88 0-1.082.217-1.18 1.511-.689 1.06.403 1.603.352 1.815-.17.167-.41.325-.1.352.689.028.807-.222 1.266-.572 1.05-.35-.216-.621.282-.621 1.139 0 1.127-.426 1.63-1.64 1.934-1.639.411-1.64.415-1.256 5.193.21 2.63.46 5.2.552 5.713.093.513-.087 1.072-.4 1.243-.56.305-1.095-.996-1.91-4.651-.507-2.275-1.21-1.277-1.854 2.631-.413 2.503-1.094 3.29-1.825 2.108zm1.725-14.063c-.215-.56-.094-1.317.27-1.682.947-.946 2.74-.081 2.493 1.203-.275 1.43-2.266 1.775-2.763.479z"
        ></path>
      </g>
    </svg>
  );
}


export function Icon4() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="200"
      height="200"
      version="1.1"
      viewBox="0 0 52.917 52.917"
      xmlSpace="preserve"
    >
      <defs>
        <color-profile
          name="Black---White"
          xlinkHref="file:///Library/ColorSync/Profiles/Black%20&amp;%20White.icc"
        ></color-profile>
      </defs>
      <g>
        <circle
          cx="26.458"
          cy="26.458"
          r="12"
          fill="#fff"
          fillOpacity="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.27"
          paintOrder="stroke fill markers"
          stopColor="#000"
        ></circle>
        <path
          fill="#000"
          strokeWidth="0.545"
          d="M20.458 26.458c0-5.454.205-8.727.546-8.727.3 0 .545.213.545.474s2.21.475 4.91.475 4.908-.214 4.908-.475c0-.26.246-.474.546-.474.34 0 .545 3.273.545 8.727 0 5.455-.204 8.728-.545 8.728-.339 0-.546-3-.546-7.91 0-6.308-.15-7.909-.742-7.909-.49 0-.657.447-.491 1.316.18.94-.075 1.49-.894 1.928-1.046.56-1.145 1.085-1.145 6.048 0 3.26-.219 5.436-.546 5.436-.3 0-.545-1.228-.545-2.728 0-1.5-.246-2.727-.546-2.727-.3 0-.545 1.227-.545 2.727 0 1.5-.246 2.728-.546 2.728-.327 0-.545-2.176-.545-5.436 0-4.963-.1-5.488-1.145-6.048-.82-.438-1.074-.987-.894-1.928.166-.87 0-1.316-.491-1.316-.593 0-.743 1.6-.743 7.91 0 4.909-.207 7.909-.545 7.909-.341 0-.546-3.273-.546-8.728zm7.894-4.557c.619-.453.877-1.087.667-1.637-.482-1.255-4.64-1.255-5.12 0-.381.992 1.11 2.376 2.56 2.376.485 0 1.337-.333 1.893-.74zm-2.985-.897c0-.727.364-1.091 1.091-1.091.728 0 1.091.364 1.091 1.09 0 .728-.363 1.092-1.09 1.092-.728 0-1.092-.364-1.092-1.091z"
        ></path>
      </g>
    </svg>
  );
}




import React from 'react';
import Loader from '../../../../helpers/Loader/loader';
import UserCardComponent from '../../../../CommonComponents/UserCards/UserCardComponent';

import { MainServices } from '../../../../services/MainServices'
import  TextFieldComponent  from '../../../../CommonComponents/InputFieds/TextField';
import { Link } from "react-router-dom";
import { BaseLogicHOC } from '../../../HOC/BaseLogicHOC';
import { NavigationBarHOC } from '../../../HOC/NavigationBarHOC';
import { getStringFromDate, isDateLessThanToday } from '../../../../helpers/Functions/DateToString';
import { PhoneIcon } from '../../../../CommonComponents/Icons/CommonIcons';
import ToggleComponent from '../../../../CommonComponents/Toggle/ToggleComponent';



class RollOverListComponentInner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            "res": [],
            'pendingRenewals': [],
            "showErrorState": false,
            "errorMsg": ""
        }
        this.props.setSelectedComp(7)
    }






    handleChange = (event) => {
        const { id, value } = event.target;
        this.setState({
            [id]: value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        this.props.showLoader()
        let postJson = {
            "mobileNumber": this.state.mobileNumber
        }
        MainServices.postRequest("/admin/rollover/fetch-members", postJson).then(
            res => {
                
                this.setState({"pendingRenewals": res})
                this.props.hideLoader()

            },
            error => {
                
                this.props.hideLoader()
                this.setState({
                    showErrorState: true,
                    errorMsg: error
                })
            })
    }

 

    render() {
       
        let errorState = <h1> {this.state.errorMsg}</h1>

        
            return (
                <div>
                
                <section className="">
                    <div className="main-card">
                        <div className="flex flex-col min-w-0 break-words w-full mb-6 border-0 ">
                            <div className='mb-6 '>
                                <form onSubmit={this.handleSubmit}>
                                <TextFieldComponent changeFn={this.handleChange} id="mobileNumber" text="Mobile Number" type="number" isRequired={true} />
                                <button type="submit" className="text-white bg-gray-700 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Search</button>
                                </form>
                            </div>
                            
                            {this.state.showErrorState ? errorState : null}

                            {this.state.pendingRenewals.map((member) => {
                                
                                // if(member)
                                return (<RolloverListCard member={member} />) 
                            })}

                        </div>
                    </div>
                </section>
                </div>
            )
        
        


    }
}

class RolloverListCard extends React.Component {

    
    render() {
        return (
            <div className='m-2 p-4 rounded shadow-xl bg-gray-700 text-white shadow-gray-500 shadow-md rounded-lg '>
                
                <div className='flex space-x-2'>
                    <img className='w-24 h-24 rounded-full object-cover flex-none' src={this.props.member.media.photo} />
                    <div className='flex-1'>
                        <h1 className='font-medium'>{this.props.member.name}</h1>
                        <p>{this.props.member.mobileNumber}</p>
                        <p>{getStringFromDate(this.props.member.dateOfBirth)}</p>
                        <p>Pending Amount: {this.props.member.gymMembership.pendingAmount}</p>
                        <p>Membership End Date: {getStringFromDate(this.props.member.gymMembership.membershipEndDate)}</p>
                        <p>Training: {this.props.member.gymMembership.typeOfTraining.typeOfTraining}</p>
                        <p>Duration: {this.props.member.gymMembership.duration.duration}</p>
                    </div>

                    

                    
                        

                </div>
                {isDateLessThanToday(this.props.member.gymMembership.membershipEndDate)
                    ?
                    <div>
                       ⚠ Membership has already expired, please renew membership.
                    </div>
                    : <Link to="detail" state={{member: this.props.member}}><button className='bg-white text-black p-2 m-2 rounded font-medium'>Rollover </button></Link>
                }
                
    
                
            </div>
        )
    }
}


const RollOverListComponentBase = BaseLogicHOC(RollOverListComponentInner)
const RollOverListComponent = NavigationBarHOC(RollOverListComponentBase)
export default RollOverListComponent;
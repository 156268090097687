import React, { useState, useRef } from 'react';
import { AuthService } from '../../../services/admin/AuthService';
import ManageAuth from "../../../services/admin/ManageAuth";
import { Navigate } from "react-router-dom";
import AppConfig from '../../../AppConfig';
import NavBarComponent from '../../../CommonComponents/NavBarComponent';
import Loader from '../../../helpers/Loader/loader';
import { MainServices } from '../../../services/MainServices';
import CreatableSelect, { useCreatable } from 'react-select/creatable';
import Webcam from "react-webcam";
import styles from '../sign.css'
// import {Camera} from "react-camera-pro";
import SignaturePad from 'react-signature-canvas'
import CameraComponent from '../Camera/CameraComponent';
import 'react-html5-camera-photo/build/css/index.css';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import InfoModalComponent from '../../../CommonComponents/InfoModal/InfoModalComponent';
import TextFieldComponent from '../../../CommonComponents/InputFieds/TextField';
import MemberSuccessComponent from './MemberSuccessComponent';
import CameraFullPageComponent from '../../../CommonComponents/Camera/CameraFullPageComponent';


class AddNewMebershipComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "isLoading": true,
            'loaderMsg': "",
            "typeOfTraining": '',
            "isSecondScreen": false,
            'successScreen': false,
            'showModal': false,
            'modalType': 'success',
            'modalMessage': 'Test',
            'successMsg': ''
        };
        this.durationArr = []
        this.timeSlotData = []
        this.height = []

        this.setupTimeSlots()
        this.setupHeight()
    }

    hideModal = () => {
        this.setState({
            "modalMessage": '',
            "showModal": false,
            'modalType': '',
        })
    }

    showModal = (modalMessage, modalType) => {
        this.setState({
            "modalMessage": modalMessage,
            "showModal": true,
            'modalType': modalType,
        })
    }

    setLoading = (value) => {
        this.setState({isLoading: value})
    }

    setupTimeSlots = () => {
        for (let i = 6; i < 12; i++) {
            let val = i + " am - " + (i + 1) + " am"
            let data = { value: val, label: val }
            this.timeSlotData.push(data)
            if (i == 11) {

                let data2 = { value: "11:30 am - 12:30 pm", label: "11:30 am - 12:30 pm" }
                this.timeSlotData.push(data2)
            } else {
                let val2 = i + ":30 am - " + (i + 1) + ":30 am"
                let data2 = { value: val2, label: val2 }
                this.timeSlotData.push(data2)
            }
        }
        this.timeSlotData.push({ value: "12 pm - 1 pm", label: "12 pm - 1 pm" },)
        this.timeSlotData.push({ value: "12:30 pm - 1:30 pm", label: "12:30 pm - 1:30 pm" },)

        for (let i = 1; i < 10; i++) {
            let val = i + " pm - " + (i + 1) + " pm"
            let data = { value: val, label: val }
            this.timeSlotData.push(data)
            if (i == 9) {

            } else {
                let val2 = i + ":30 pm - " + (i + 1) + ":30 pm"
                let data2 = { value: val2, label: val2 }
                this.timeSlotData.push(data2)
            }
        }
    }

    setupHeight = () => {
        for (let i = 100; i < 216; i++) {
            let data = i
            this.height.push(data)
        }
    }


    setLoading = (value) => {
        this.setState({ "isLoading": value })
    }

    componentDidMount() {
        MainServices.getRequest("/admin/get-pricing").then(
            res => {
                
                this.data = res
                this.setLoading(false)

            },
            error => {
                
                this.setLoading(false)
            })
    }


    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ isSecondScreen: true })
        window.scrollTo(0, 0)
    }

    calculateAge = dob => {
        const birthDate = new Date(dob);
        const difference = Date.now() - birthDate.getTime();
        const age = new Date(difference);
        return Math.abs(age.getUTCFullYear() - 1970);
    }

    handleChange = (event) => {
        const { id, value } = event.target;
        if (id == "dateOfBirth") {
            let dateObj = new Date(value)
            this.setState({
                age: this.calculateAge(dateObj)
            })
        }
        this.setState({
            [id]: value
        })
    }

    handleSelectTraining = (event) => {
        let val = event.target.value
        let selectedTraining = this.data.filter((obj) => (obj._id == val))
        this.pricingArrForSelectedTraining = selectedTraining[0].pricing
        
        this.setState({
            "typeOfTraining": val,
        })
    }

    finalSubmit = (pic, sign) => {
        this.setState({
            "photo": pic,
            "signature": sign
        }, () => {this.postServiceCall()})
        
        this.setState({ loaderMsg: "Please wait while we submit the registration form..."})
        this.setLoading(true)
        // this.setLoaderMsg("Please wait while we submit your application")
        
        
    }

    postServiceCall = () => {
        MainServices.postRequestWithoutAuth("/user/add-new-member",this.state).then(
            res => {
                this.setLoading(false)
                let endDate = new Date(res.endDate).toDateString()
                let msg = "Registration Successfull, please contact Beyond Fitness. Your Membership expires on" + endDate
                
                this.setState({successMsg: msg, successScreen: true})
                
                // this.showModal(msg, 'success')
            },
            error => {
                this.setLoading(false)
                this.showModal("Something went wrong", 'failure')
                
            })
        
    }


    render() {
        if (this.state.isLoading) {
            return (<Loader msg={this.state.loaderMsg}/>)
        }
        let sucess = null
        if(this.state.successScreen) {
            return <MemberSuccessComponent msg={this.state.successMsg} />
        }

        return (<div>
            <NavBarComponent />
            <InfoModalComponent
                    showModal={this.state.showModal}
                    modalType={this.state.modalType}
                    modalMessage={this.state.modalMessage}
                    hideModal={this.hideModal}
                />
            <section className="">
                <div className="main-card">
                    <div className="">

                        <h6 className="text-blueGray-500 p-4 text-sm font-bold">
                            Please fillout the form
                        </h6>
                        

                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                            {!this.state.isSecondScreen ? <form onSubmit={this.handleSubmit}>
                                    <TextFieldComponent changeFn={this.handleChange} id="name" text="Name" type="text" isRequired={true} />

                                    <TextFieldComponent changeFn={this.handleChange} id="dateOfBirth" text="Date of birth" type="date" isRequired={true} />
                                
                                    {/* Gender */}
                                    <div className="mb-6 p-2">
                                        <label htmlFor="height" className="input-label">Gender *</label>

                                        <select
                                            onChange={this.handleChange}
                                            id="gender" className="input-bf" required>
                                            <option value="">Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                    </div>

                                {this.state.age ? <label htmlFor="height" className="input-label">Age: {this.state.age}</label> : null}
                                <div className='grid  grid-cols-2 divide-x'>
                                    {/* Height */}
                                    <div className="p-2">
                                        <label htmlFor="height" className="input-label">Height *</label>
                                        <div className='flex'>
                                            <select onChange={this.handleChange} id="height" className="input-bf" required>
                                                <option value="">Select</option>
                                                {this.height.map((height) => {
                                                    // 
                                                    return <option key={height} value={height}>{height} cm</option>
                                                })}
                                            </select>            
                                        </div>
                                    </div>

                                    {/* Wight */}
                                    <TextFieldComponent changeFn={this.handleChange} id="weight" text="Weight" type="number" isRequired={true} />
                                </div>

                                {/* mobile */}
                                <TextFieldComponent changeFn={this.handleChange} id="mobileNumber" text="Mobile Number" type="number" isRequired={true} />

                                {/* emergency */}
                                <TextFieldComponent changeFn={this.handleChange} id="emergencyContact" text="Emergency Number" type="number" isRequired={true} />

                                {/* email */}
                                <TextFieldComponent changeFn={this.handleChange} id="email" text="Email" type="text" isRequired={true} />

                                {/* residentialAddress */}
                                <TextFieldComponent changeFn={this.handleChange} id="residentialAddress" text="Address" type="text" isRequired={false} />

                                {/* Type of training */}
                                <div className="mb-6 p-2">
                                    <label htmlFor="height" className="input-label">Type of training *</label>

                                    <select
                                        onChange={this.handleSelectTraining}
                                        id="typeOfTraining" className="input-bf" required>
                                        <option value="">Select type of Training</option>
                                        
                                        {this.data.map((trainingType) => {
                                            
                                            return <option key={trainingType.id} value={trainingType._id}>{trainingType.typeOfTraining}</option>
                                        })}
                                    </select>
                                </div>

                                {/*  duration */}
                                {this.state.typeOfTraining == '' ? <></> :
                                    <div className="mb-6 p-2">
                                        <label htmlFor="height" className="input-label">Duration *</label>

                                        <select
                                            onChange={this.handleChange}
                                            id="duration" className="input-bf" required>
                                            <option value="">Select Duration</option>
                                            {this.pricingArrForSelectedTraining.map((pricing) => {

                                                return <option key={pricing.id} value={pricing._id}>{pricing.duration} Cost: {pricing.price}</option>
                                            })}
                                        </select>
                                    </div>
                                }


                                {/* dateOfJoining */}
                                <TextFieldComponent changeFn={this.handleChange} id="dateOfJoining" text="Date of joining" type="date" isRequired={true} />

                                {/* Time Slot */}
                                <div className="mb-6 p-2">
                                    <label htmlFor="mobile" className="input-label">Time Slot</label>
                                    {/* <CreatableSelect isClearable options={this.timeSlotData} /> */}

                                    <select onChange={this.handleChange} id="timeSlot" className="input-bf">
                                        <option value="">Select timeslot</option>
                                        {this.timeSlotData.map((height) => {
                                            // 
                                            return <option key={height.label} value={height.label}>{height.label}</option>
                                        })}
                                    </select>
                                </div>

                                {/* Injuries */}
                                <TextFieldComponent changeFn={this.handleChange} id="injuries" text="Injuries if any" type="text" isRequired={false} />

                                {/* purposeOfTraining */}
                                {/* purposeOfTraining */}
                                <div className="p-2">
                                    <label htmlFor="height" className="input-label">Purpose Of Training *</label>
                                    <div className='flex'>
                                        <select onChange={this.handleChange} id="purposeOfTraining" className="input-bf" required>
                                            <option value="">Select</option>
                                            {AppConfig.purposeList.map((purpose) => {
                                                // 
                                                return <option key={purpose} value={purpose}>{purpose}</option>
                                            })}
                                        </select>


                                    </div>
                                </div>
                                {/* <TextFieldComponent changeFn={this.handleChange} id="purposeOfTraining" text="Purpose of training" type="text" isRequired={false} /> */}
                                
                                <div className="text-center mt-6">
                                    <button type="submit" className="button-bf">Next</button>
                                </div>
                            </form> :
                                <div>

                                    <SecondScreen submitFinalFn={this.finalSubmit} />
                                </div>
                            }

                        </div>
                    </div>
                </div>

            </section>
        </div>)
    }
}


export default AddNewMebershipComponent;




class SecondScreen extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            trimmedDataURL: null,
            "showCamera": false,
            "cameraError": false,
            facingMode: FACING_MODES.USER,
            'showModal': false,
            'modalType': 'success',
            'modalMessage': 'Test',
        }
        this.sigPad = {}
    }

    

clear = () => {
    this.sigPad.clear()
}
trim = () => {
    
    
    
    let sign = this.sigPad.getTrimmedCanvas()
        .toDataURL('image/png')
    
    if (this.sigPad.isEmpty()) {
        
        this.showInfoModal("Please sign", 'failure')
        return
    }
    
    if(this.state.pic) {
        
        
        this.props.submitFinalFn(this.state.pic, sign)
        return
    }

    
    if(!this.state.cameraError) {
        
        this.showInfoModal("Please take a selfie", "failure")
        return
    } 

    if(this.state.cameraError) {
        
        this.props.submitFinalFn(this.state.pic, sign)
        return
    }

    

    
    
}

    showInfoModal = (message, modalType) => {
        this.setState({
            'showModal': true,
            'modalType': modalType,
            'modalMessage': message,
        })
    }

    hideModal = () => {
        this.setState({
            'showModal': false
        })
    }


    setPic = (pic) => {
        alert("called", pic)
        this.setState({ "pic": pic, "showCamera": false })
    }

    takePic = () => {
        this.setState({ showCamera: true })
    }

    takeSignAndSubmit = (sign) => {
        // this.setState({signature: sign})
        
    }

    

    reverseCamera = () => {
        if (this.state.facingMode === FACING_MODES.USER) {
            this.setState({ facingMode: FACING_MODES.ENVIRONMENT })
        } else {
            this.setState({ facingMode: FACING_MODES.USER })
        }
    }
    cameraHandler = (photo, isCameraError) => {
        
        if(isCameraError) {
            this.setState({
                "showCamera": false,
                cameraError: true
            })
            return
        } else {
            
            this.setState({
                showCamera: false,
                pic: photo
            })
        }
    }


    render() {
        if(this.state.showCamera) {
            return (<CameraFullPageComponent handler={this.cameraHandler} />)
        }
        let signJSX = (<div className=''>
        <h2>Please sign in the below box</h2>
        <div className='border-2'>
    
            <SignaturePad canvasProps={{ className: "h-48 w-full" }}
                ref={(ref) => { this.sigPad = ref }} />
            <button type="button" className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                onClick={this.clear}>Clear Sign</button>
        </div>
        <div>
    
    
    
    
        </div>
        {/* {this.state.trimmedDataURL
                ? <img className={styles.sigImage}
                    src={this.state.trimmedDataURL} />
                : null} */}
        <button type="button" className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
            onClick={this.trim}>Submit</button>
    </div>)

        let cameraObj = <div>
        
            <button type="button"
                className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                onClick={this.takePic}>
                Take a selfie *</button>
                
        
        </div> 

        let cameraErrorView = <div>
            <h1>⚠ No Camera Device found</h1>
        </div>

        let chooseFileObj = <div>
            {/* To be implemented later */}
        </div>

        let termsAndCondition = <div className='p-2'>
        <ul className='list-disc'>
            <li className='m-2'>
                I ACKNOWLEDGE THAT, I have voluntarily agreed in undertaking physical activities and maybe exposed to
                certain risks while participating into physical training activities. The activities include Strength training,
                Functional fitness, personal training, mind to body training, and group personal training, or other services
                provided by "BEYOND FITNESS", and all these activities conducted on and off the gym premises.
            </li>
            <li className='m-2'>
                I ACKNOWLEDGE THAT, I agree to all terms and conditions and “BEYOND FITNESS" shall not be held
                responsible for any unanticipated situations caused during or after training. Any damages caused due to my
                negligence or willful intention and I shall pay for all the equipment damage and losses whatsoever.
            </li>
            <li className='m-2'>I ACKNOWLEDGE THAT, "BEYOND FITNESS" shall not provide refund under any circumstances.</li>
        </ul>
    </div>
        return (
            <div>
                <InfoModalComponent
                    showModal={this.state.showModal}
                    modalType={this.state.modalType}
                    modalMessage={this.state.modalMessage}
                    hideModal={this.hideModal}
                />
                {this.state.cameraError ? 
                cameraErrorView
                : 
                cameraObj
                }
                


                {this.state.pic
                    ? <img className={styles.sigImage}
                        src={this.state.pic} />
                    : null}
                {this.state.pic ? <button className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" onClick={this.takePic}>Take Pic again</button> : <></>}
                {termsAndCondition}
                {signJSX}

            </div>
        )
    }
}





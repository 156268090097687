import 'react-html5-camera-photo/build/css/index.css';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import React, { useState, useRef } from 'react';


export default class CameraFullPageComponent extends React.Component {

    constructor(props) {
      super(props)
      this.state = {
        'facingMode': FACING_MODES.USER
      }
      this.myTimeout = null 
    }

    componentDidMount() {
     
      if(this.props.cameraFace === 'env') {
        this.setState({
          'facingMode': FACING_MODES.ENVIRONMENT
        })
      }  
    }

    componentWillUnmount() {
      console.log('🚀🚀🚀== CameraFullPageComponent.js [26] ===');
      clearTimeout(this.myTimeout)
    }
    
      handleTakePhotoAnimationDone = (dataUri) => {
        // Do stuff with the photo...
        
        this.props.handler(dataUri, false)
      }
    
      handleCameraError = (error) => {
        console.log('🚀🚀🚀=== error CameraFullPageComponent.js [37] ===', error);
        this.props.handler("", true)
      }
    
      handleCameraStart = (stream) =>  {
        
      }
    
      handleCameraStop = () => {
        
      }

      
        

      


    render() {
        return (
          <div className='fixed top-0 left-0 right-0 bottom-0 '>
                <Camera
                    onTakePhotoAnimationDone={(dataUri) => { this.handleTakePhotoAnimationDone(dataUri); }}
                    onCameraError={(error) => { this.handleCameraError(error); }}
                    idealFacingMode={FACING_MODES.USER}
                    idealResolution={{ width: 333.33, height: 250 }}
                    imageType={IMAGE_TYPES.JPG}
                    imageCompression={0.5}
                    isMaxResolution={false}
                    isImageMirror={true}
                    isSilentMode={false}
                    isDisplayStartCameraError={true}
                    isFullscreen={false}
                    sizeFactor={1}
                    onCameraStart={(stream) => { this.handleCameraStart(stream); }}
                    onCameraStop={() => { this.handleCameraStop(); }}
                >

                </Camera>
                <div className='fixed my-4 right-0 bottom-0 z-100000000000'>
                  <button onClick={this.reverseCamera} >Reverse</button>
                   
                </div>
          </div>
            
        )
    }
}

// class CameraFullPageComponent extends React.Component {
//   constructor(props) {
//     super(props)
//   }
  
// }
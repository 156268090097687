import React from 'react';
import { JsonToExcel, exportToExcel } from "react-json-to-excel";
import { BaseLogicHOC } from '../../../HOC/BaseLogicHOC'
import TextFieldComponent from '../../../../CommonComponents/InputFieds/TextField'
import { MainServices } from '../../../../services/MainServices';
import { getStringFromDate } from '../../../../helpers/Functions/DateToString';
import { JsonToTable } from "react-json-to-table";
import { NavigationBarHOC } from '../../../HOC/NavigationBarHOC';


const samplejson2 = [
    { name: 'name01', age: '20', sex: 'M' },
    { name: 'name02', age: '22', sex: 'F' },
    { name: 'name03', age: '20', sex: 'M' },
];
class ReportComponentPrivate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            res: null,
            pricingData: null
        }
        this.props.setSelectedComp(5)
    }

    componentDidMount() {
        this.fetchPricing()
    }

    fetchPricing = () => {
        this.props.showLoader()
        MainServices.getRequest("/admin/get-pricing").then(
            res => {
                
                this.data = res
                this.setState({
                    pricingData: res
                })
                this.props.hideLoader()

            },
            error => {
                
                this.props.hideLoader()
            })
    }
    handleclick = (id) => {
        
    }

    handleChange = (event) => {

        const { id, value } = event.target;
        this.setState({
            [id]: value
        })

    }

    handleSubmit = (e) => {
        e.preventDefault()
        let postJson = {
            startDate: this.state.startDate,
            endDate: this.state.endDate
        }
        this.props.showLoader()
        MainServices.postRequest('/admin/reports', postJson).then(
            res => {
                console.log('🚀🚀🚀=== res ReportComponent.js [69] ===', res);
                this.setState({
                    res: res
                })
                
                let excelArr = []
                for (let i = 0; i < res.length; i++) {
                    let transact = res[i]
                    let pricingAndTypeData = this.state.pricingData
                    let setTrainingType
                    for (let i = 0; i < pricingAndTypeData.length; i++) {
                        if (pricingAndTypeData[i]._id === transact.gymMembership.typeOfTraining) {
                            setTrainingType = pricingAndTypeData[i]
                            break
                        }
                    }
                    let setDuration
                    for (let i = 0; i < setTrainingType.pricing.length; i++) {

                        if (setTrainingType.pricing[i]._id === transact.gymMembership.duration) {
                            setDuration = setTrainingType.pricing[i]
                            break
                        }
                    }

                    

                    // let duration = trainingType[0].filter((obj) => {
                    //     if(obj._id === transact.gymMembership.duration) {
                    //         return obj
                    //     }
                    // })
                    // 
                    let json = {
                        "Client Code": transact.gymMembership.gymMember.clientCode,
                        "Mobile Number": transact.gymMembership.gymMember.mobileNumber,
                        "Name": transact.gymMembership.gymMember.name,
                        "Date of Birth": getStringFromDate(transact.gymMembership.gymMember.dateOfBirth),
                        "Payment Type": transact.paymentType,
                        "Date of joining": getStringFromDate(transact.gymMembership.dateOfJoining),
                        "Type of training": setTrainingType.typeOfTraining,
                        "Duration": setDuration.duration,
                        "Payment Date": getStringFromDate(transact.dateOfPayment),
                        "Amount": transact.amount,
                        "Payment Mode": transact.modeOfPayment,
                        "Remarks": transact.remarks,
                        "Received By": transact.receivedBy
                    }
                    excelArr.push(json)
                }
                this.setState({
                    excelArr: excelArr
                })
                this.props.hideLoader()
            },
            error => {
                
                this.props.hideLoader()
            }
        )
    }

    downloadExcel = () => {
        let fileName = "Report From " + getStringFromDate(this.state.startDate) + " To " + getStringFromDate(this.state.endDate) + "Generated at " + new Date()
        exportToExcel(this.state.excelArr, fileName)
    }

    render() {

        return (
            <div className=''>
                <section className='main-card p-2'>
                    <form onSubmit={this.handleSubmit}>
                        <TextFieldComponent changeFn={this.handleChange} id="startDate" text="Start Date" type="date" isRequired={true} />
                        <TextFieldComponent changeFn={this.handleChange} id="endDate" text="End Date" type="date" isRequired={true} />
                        <button type="submit" className="button-bf">Get Report</button>
                    </form>

                    <br />
                    {this.state.res ?
                        <div className='my-4 py-2 border-t border-gray-800'>
                            <button className='button-bf' onClick={this.downloadExcel}>
                                Download Excel
                            </button>
                            <JsonToTable json={this.state.excelArr} />
                        </div>
                        :
                        null
                    }

                </section>
            </div>
        )
    }
}

const ReportTransactionComponent = NavigationBarHOC(BaseLogicHOC(ReportComponentPrivate));

export default ReportTransactionComponent
import React from "react"

export default class TextFieldControlledComponent extends React.Component {
    componentDidMount() {
        // 
    }

    render() {
        return (
            <div className='p-2'>
                <label for="mobile" className="input-label">{this.props.text}  {this.props.isRequired ? <a>*</a> : null}</label>
                <input type={this.props.type} id={this.props.id}
                    className="input-bf"
                    onWheel={ event => event.target.blur() }
                    placeholder={"Please enter " + this.props.text} required={this.props.isRequired}
                    value={this.props.value}
                    onChange={this.props.changeFn} disabled={!this.props.isEditable}
                />
            </div>
        )
    }
}


import React from 'react';
import { Link } from 'react-router-dom';
import TextFieldComponent from '../../../../CommonComponents/InputFieds/TextField';
import UserCardComponent from '../../../../CommonComponents/UserCards/UserCardComponent';
import { getStringFromDate } from '../../../../helpers/Functions/DateToString';
import { MainServices } from '../../../../services/MainServices';
import { BaseLogicHOC } from '../../../HOC/BaseLogicHOC';
import { NavigationBarHOC } from '../../../HOC/NavigationBarHOC';



class PendingPaymentComponentInner extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            "res": [],
            "initRes": [],
            "errorStateCheck": false
        }
        this.props.setSelectedComp(2)
        this.fetchData()
    }

    fetchData = () => {
        this.props.showLoader()
      
        MainServices.postRequest("/admin/fetch-pending-datainit").then(
            res => {
                
                this.setState({ initRes: res })
                this.props.hideLoader()

            },
            error => {
                
                this.props.hideLoader()
            })
    }

    handleclick = (id) => {
        
    }


    handleChange = (event) => {
        const { id, value } = event.target;
        this.setState({
            [id]: value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        this.props.showLoader()
        let postJson = {
            "mobileNumber": this.state.mobileNumber
        }
        MainServices.postRequest("/admin/fetch-pending-members", postJson).then(
            res => {
                
                this.setState({ res, errorStateCheck: true })
                this.props.hideLoader()

            },
            error => {
                
                this.props.hideLoader()
            })
    }


    render() {
        let errorState 
        if(this.state.errorStateCheck) {
            if(this.state.res.length === 0) {
                errorState = <h1>No active user found</h1>
            } else {
                errorState = (this.state.res.map((member) => {
                    
                    return (<Link to="receive-pending" state={{ member: member }}>
                        <UserCardComponent member={member} />
                    </Link>)
                }))
            }
        }
        let initres = (
            <div>
                <h1 className='medium-text'>Pending Payments From the below members</h1>
            {this.state.initRes.map( (member) => {
            return (
                <Link to="receive-pending" state={{ member: member.gymMember }}>
                        
                        <PendingUserCard member={member} />
                    </Link>
            )
        })}
        </div>
        )
        

        return (
            <div>

                <section className="">
                    <div className="main-card">
                        <div className="flex flex-col min-w-0 break-words w-full mb-6 border-0 ">
                            <div className='mb-6 '>
                                <form onSubmit={this.handleSubmit}>
                                    <TextFieldComponent changeFn={this.handleChange} id="mobileNumber" text="Mobile Number/Name" type="text" isRequired={true} />
                                    <button type="submit" className="text-white bg-gray-700 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Search</button>
                                </form>
                            </div>
                            {this.state.errorStateCheck ? errorState : initres}
                            

                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

class PendingUserCard extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <div className='m-2 p-4 rounded shadow-xl bg-gray-700 text-white shadow-gray-500 shadow-md rounded-lg '>
                <div className='flex space-x-2'>
                    
                    <div className='flex-1'>
                        <h1 className='font-medium'>Name: {this.props.member.gymMember.name}</h1>
                        <p>Mobile Number: {this.props.member.gymMember.mobileNumber}</p>
                        <p>Date of Birth: {getStringFromDate(this.props.member.gymMember.dateOfBirth)}</p>
                        <p>Pending Amount: {this.props.member.pendingAmount}</p>
                    </div>

                </div>
                
            </div>
        )
    }
}


const PendingPaymentComponent = BaseLogicHOC(NavigationBarHOC(PendingPaymentComponentInner))
export default PendingPaymentComponent